import anime from 'animejs';
import React from 'react';

export class Error404 extends React.Component {
  componentDidMount() {
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.contentpage__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__subtitle',
        opacity: [0, 0.7],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }
  render() {
    return (
      <main>
        <header className="contentpage__header">
          <div className="wrapper wrapper--medium">
            <div className="contentpage__title">
              <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
                Error 404
              </p>
              <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                Not found
              </h1>
            </div>
            <div className="contentpage__subtitle u-type--zeta u-type--secondary u-type--typography--wide u-opacity--70">
              The page you are searching for could not be found. If you entered
              a web address please check if it was correct. Or you can always go
              to Tolar.io <a href="/">homepage</a>.
            </div>
          </div>
        </header>
      </main>
    );
  }
}
