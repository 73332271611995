const downloadMainUrl =
  'https://github.com/Tolar-HashNET/Tolar-mainnet-release/releases/download';

interface Downloads {
  for: 'Chrome' | 'Mac' | 'Linux' | 'Windows';
  downloadLink: string;
  sha?: string;
  note?: string;
}
interface DownloadType {
  title: string;
  type: string;
  downloads: Downloads[];
}

export const downloadInfo: DownloadType[] = [
  // {
  //   title: 'Web wallet',
  //   type: 'Wallet',
  //   downloads: [
  //     {
  //       for: 'Chrome',
  //       downloadLink: downloadMainUrl + '/taquin_1.0.0_v5.zip',
  //       note:
  //         'After unpacking ZIP file, go to chrome://extensions/ and check the box for Developer mode' +
  //         ' in the top right. Once you have enabled it, you can click on "Load unpacked"' +
  //         ' button and browse to location where you extracted ZIP with Tolar web wallet.',
  //     },
  //   ],
  // },
  {
    title: 'Command line node binary for Mainnet',
    type: 'Binary',
    downloads: [
      {
        for: 'Mac',
        downloadLink: downloadMainUrl + '/v1.1.00/macOS-node-v1-1.zip',
        sha: 'fc995f9ef8955916893e880689ff2fcc8248775e1d13a82264f83f96af3801c7',
      },
      {
        for: 'Linux',
        downloadLink: downloadMainUrl + '/v1.1.00/linux-node-v1-1.zip',
        sha: 'b0705875dd0e22425fe62178d597703157cec14f536d1f0184793210c5baa2b9',
      },
      {
        for: 'Windows',
        downloadLink: downloadMainUrl + '/v1.1.00/win-node-v1-1.zip',
        sha: '96a1380895897c1e5994b5c714f58a99a09e94f9147210eed7492a8d93e5c67a',
      },
    ],
  },
  {
    title: 'GUI wallet for Mainnet',
    type: 'Wallet',
    downloads: [
      {
        for: 'Mac',
        downloadLink: downloadMainUrl + '/v1.1.00/Tolar.Wallet-1.1.0.dmg',
        sha: '7a6adf11cb59549052ebc4897ad4073362ff763261e0001197f1dffd13490812',
      },
      {
        for: 'Linux',
        downloadLink: downloadMainUrl + '/v1.1.00/tolar-wallet_1.1.0_amd64.deb',
        sha: '73d863bc22d00f7c0fea682c7e4f94cb9f37a4a032beb0d99fa2223e7b748f0f',
      },
      {
        for: 'Windows',
        downloadLink: downloadMainUrl + '/v1.1.00/Tolar.Wallet.Setup.1.2.1.exe',
        sha: '4de6a0375fb420aa626987679849ece1e5f1f8a899fa40c189fd2fa45f902ad2',
      },
    ],
  },
];
