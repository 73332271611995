import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import anime from 'animejs';
import { images } from 'main/assets';
import { MobileNav, NavItem, NavItemDownload } from '.';

export const Navigation: React.FC = () => {
  const [isMenuToggled, toggleMenu] = useState<boolean>(false);
  const [toggledSubmenuId, toggleSubmenuId] = useState<number>();

  function handleBlur(event: any) {
    event.currentTarget ? event.currentTarget.blur() : event.srcElement.blur();
  }

  function removeBodyLock() {
    document.body.classList.remove('is-locked');
    document.documentElement.classList.remove('is-locked');

    const documentElement = document.getElementsByClassName('.nav__links')[0];
    enableBodyScroll(documentElement);
  }

  function handleMenuToggle() {
    toggleMenu(!isMenuToggled);

    if (isMenuToggled) {
      removeBodyLock();
    } else {
      document.body.classList.add('is-locked');
      document.documentElement.classList.add('is-locked');

      const documentElement = document.getElementsByClassName('.nav__links')[0];
      disableBodyScroll(documentElement);

      const menuAnimation = anime.timeline();

      menuAnimation.add({
        targets: ['#animate .nav__links__item'],
        translateX: ['-10%', '0'],
        opacity: [0, 1],
        direction: 'alternate',
        loop: true,
        duration: 800,
        easing: 'easeInOutQuart',
        delay: (_el, i) => {
          return i * 100;
        },
      });
    }
  }

  function handleSubmenuToggle(event: any) {
    const { submenuId } =
      event.currentTarget.dataset || event.srcElement.dataset;

    const newSubmenuId =
      toggledSubmenuId === parseInt(submenuId)
        ? undefined
        : parseInt(submenuId);

    toggleSubmenuId(newSubmenuId);
  }

  return (
    <header className="header">
      <nav className={`nav ${isMenuToggled ? 'is-open' : ''}`}>
        <div className="nav__branding">
          <NavLink to="/" exact className="" onClick={removeBodyLock}>
            <img src={images.complexLogo} alt="Tolar logo" className="logo" />
          </NavLink>
        </div>
        <ul
          id="animate"
          className={`nav__links ${isMenuToggled ? 'is-open' : ''}`}
        >
          <NavItem
            title="HashNET"
            to="/hashnet"
            handleBlur={handleBlur}
            removeBodyLock={removeBodyLock}
            handleSubmenuToggle={handleSubmenuToggle}
            toggledSubmenuId={toggledSubmenuId}
          />

          <NavItem
            hasSubmenu
            title="Developers"
            to=""
            handleBlur={handleBlur}
            removeBodyLock={removeBodyLock}
            handleSubmenuToggle={handleSubmenuToggle}
            toggledSubmenuId={toggledSubmenuId}
          >
            <NavItem
              hasSubmenu
              hasSideMenu
              title="Documentation"
              to=""
              handleBlur={handleBlur}
              removeBodyLock={removeBodyLock}
              handleSubmenuToggle={handleSubmenuToggle}
              toggledSubmenuId={toggledSubmenuId}
            >
              <NavItemDownload
                title="Grpc docs"
                fileUrl={'./gRPC_API_documentation.pdf'}
                handleBlur={handleBlur}
              />
              <NavItem
                title="JSON rpc"
                to="https://tolar-clients.kwiki.io/docs/json-rpc"
                handleBlur={handleBlur}
                removeBodyLock={removeBodyLock}
                handleSubmenuToggle={handleSubmenuToggle}
                toggledSubmenuId={toggledSubmenuId}
              />
              <NavItem
                title="web3j"
                to="https://tolar-clients.kwiki.io/docs/web3j"
                handleBlur={handleBlur}
                removeBodyLock={removeBodyLock}
                handleSubmenuToggle={handleSubmenuToggle}
                toggledSubmenuId={toggledSubmenuId}
              />
              <NavItem
                title="web3js"
                to="https://tolar-clients.kwiki.io/docs/web3js"
                handleBlur={handleBlur}
                removeBodyLock={removeBodyLock}
                handleSubmenuToggle={handleSubmenuToggle}
                toggledSubmenuId={toggledSubmenuId}
              />
            </NavItem>
            <NavItem
              title="Dev Blog (Medium)"
              to="/blog"
              handleBlur={handleBlur}
              removeBodyLock={removeBodyLock}
              handleSubmenuToggle={handleSubmenuToggle}
              toggledSubmenuId={toggledSubmenuId}
            />
            <NavItem
              title="Testnet Faucet"
              to="/testnet-faucet"
              handleBlur={handleBlur}
              removeBodyLock={removeBodyLock}
              handleSubmenuToggle={handleSubmenuToggle}
              toggledSubmenuId={toggledSubmenuId}
            />
            <NavItem
              hasSubmenu
              title="Taquin plugin"
              to="/taquin-plugin"
              handleBlur={handleBlur}
              removeBodyLock={removeBodyLock}
              handleSubmenuToggle={handleSubmenuToggle}
              toggledSubmenuId={toggledSubmenuId}
            />
            <NavItem
              title="Tools"
              to="/download"
              handleBlur={handleBlur}
              removeBodyLock={removeBodyLock}
              handleSubmenuToggle={handleSubmenuToggle}
              toggledSubmenuId={toggledSubmenuId}
            />
            <NavItem
              title="Github"
              to="https://github.com/Tolar-HashNET"
              handleBlur={handleBlur}
              removeBodyLock={removeBodyLock}
              handleSubmenuToggle={handleSubmenuToggle}
              toggledSubmenuId={toggledSubmenuId}
            />
          </NavItem>

          <NavItem
            title="Wallets"
            to="/taquin-plugin"
            handleBlur={handleBlur}
            removeBodyLock={removeBodyLock}
            handleSubmenuToggle={handleSubmenuToggle}
            toggledSubmenuId={toggledSubmenuId}
          />

          <NavItem
            hasSubmenu
            title="Explorer"
            to="https://explorer.tolar.io"
            handleBlur={handleBlur}
            removeBodyLock={removeBodyLock}
            handleSubmenuToggle={handleSubmenuToggle}
            toggledSubmenuId={toggledSubmenuId}
          >
            <NavItem
              title="Mainnet"
              to="https://web-explorer.mainnet.tolar.io"
              handleBlur={handleBlur}
              removeBodyLock={removeBodyLock}
              handleSubmenuToggle={handleSubmenuToggle}
              toggledSubmenuId={toggledSubmenuId}
            />
            <NavItem
              title="Testnet"
              to="https://web-explorer.testnet.tolar.io"
              handleBlur={handleBlur}
              removeBodyLock={removeBodyLock}
              handleSubmenuToggle={handleSubmenuToggle}
              toggledSubmenuId={toggledSubmenuId}
            />
          </NavItem>

          <NavItem
            title="enterprise"
            to="/Enterprise"
            handleBlur={handleBlur}
            removeBodyLock={removeBodyLock}
            handleSubmenuToggle={handleSubmenuToggle}
            toggledSubmenuId={toggledSubmenuId}
          />

          <MobileNav />
        </ul>
        <div className="nav__right">
          <button
            onClick={handleMenuToggle}
            className={`nav__link nav__link--toggle-menu ${
              isMenuToggled ? 'is-open' : ''
            }`}
          >
            <div className={`hamburger ${isMenuToggled ? 'is-open' : ''}`}>
              <div className="line-menu half start" />
              <div className="line-menu" />
              <div className="line-menu half end" />
            </div>
            Menu
          </button>
        </div>
      </nav>
    </header>
  );
};
