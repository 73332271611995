import { NavLink } from 'react-router-dom';

interface Props {
  title: string;
  to: string;
  hasSubmenu?: boolean;
  hasSideMenu?: boolean;
  handleBlur: (event: any) => void;
  removeBodyLock: (event: any) => void;
  handleSubmenuToggle: (event: any) => void;
  toggledSubmenuId?: number;
}

export const NavItem: React.FC<Props> = ({
  title,
  hasSubmenu,
  hasSideMenu,
  handleBlur,
  removeBodyLock,
  handleSubmenuToggle,
  toggledSubmenuId,
  to,
  children,
}) => {
  return (
    <li
      className={`nav__links__item ${
        hasSubmenu ? 'nav__links__item--dropdown' : ''
      } ${hasSideMenu ? 'nav__links__item--dropside' : ''}`}
    >
      {to.includes('http') ? (
        <a
          onMouseLeave={handleBlur}
          className="nav__link"
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>
      ) : (
        <NavLink
          onMouseLeave={handleBlur}
          to={to}
          exact
          className="nav__link"
          activeClassName="active"
          onClick={removeBodyLock}
        >
          {title}
        </NavLink>
      )}
      {hasSubmenu && (
        <div
          onClick={handleSubmenuToggle}
          data-submenu-id={1}
          className={`nav__link__expand ${
            toggledSubmenuId === 1 ? 'is-open' : ''
          }`}
        />
      )}
      {children && (
        <ul
          className={`nav__sublinks ${
            hasSideMenu ? 'nav__sublinks--aside' : ''
          }`}
        >
          {children}
        </ul>
      )}
    </li>
  );
};
