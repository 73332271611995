import anime from 'animejs';

import ScrollableAnchor from 'react-scrollable-anchor';

import { images } from 'main/assets';
import React from 'react';

export class Staking extends React.Component {
  handleNextSection() {
    window.scrollBy({ top: window.innerHeight - 200, behavior: 'smooth' });
  }

  componentDidMount() {
    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.contentpage__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.staking__header__content',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.staking__header__button',
        opacity: [0, 1],
        translateY: [20, 0],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }

  async copyToClipboard(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const data = event.currentTarget.innerHTML;
    const clipboard = (navigator as any).clipboard;

    if (typeof clipboard === 'undefined') {
      const textArea = document.createElement('textarea');
      textArea.value = data;
      textArea.style.position = 'fixed'; // avoid scrolling to bottom

      document.body.appendChild(textArea);

      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (_) {
        console.error('Failed copy to clipboard action!');
      }

      document.body.removeChild(textArea);
      return;
    }
    await clipboard.writeText(data);
  }

  render() {
    return (
      <>
        <section className="contentpage js-section-staking">
          {/* <SEO {...this.seoProps} /> */}
          <style
            dangerouslySetInnerHTML={{
              __html: `
        .u-bg--video { opacity: 0.2 }
        .u-bg--deco { display: none }
        .u-bg--space { display: none }
      `,
            }}
          />
          <header className="contentpage__header staking__title">
            <div className="u-grid wrapper staking__wrapper">
              <div className="u-grid-lrg__col2">&nbsp;</div>
              <div className="u-grid-lrg__col8">
                <div className="contentpage__title contentpage__title--left-aligned wrapper--small">
                  {/* <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
                    Tolar staking
                  </p> */}
                  <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                    Tolar staking
                  </h1>
                </div>
                <div className="staking__header__content u-spc-bottom--lrg u-opacity--60 u-type--zeta u-grid">
                  <p className="u-grid-xxlrg__col6 u-spc-bottom--med staking__col">
                    Tolar HashNET uses a Proof-of-Stake consensus algorithm to
                    validate transactions and secure the network. Decentralized
                    Capital is first in offering service for staking your TOL.
                  </p>
                  <p className="u-grid-xxlrg__col6 u-spc-bottom--med">
                    In exchange, you are rewarded with TOL coins. To stake, you
                    don’t need any particular hardware or device, just a Tolar
                    wallet with mainnet Tolars.
                  </p>
                </div>
                <div className="staking_header staking__header__button">
                  <button
                    onClick={this.handleNextSection}
                    className="btn btn--primary btn--lrg u-spc-right--lrg"
                  >
                    Find out more
                  </button>
                </div>
              </div>
            </div>
          </header>
        </section>
        <main>
          <ScrollableAnchor id={'learn-more'}>
            <section className="staking__faq staking__section--top js-section-staking section--light">
              <div className="u-grid wrapper staking__wrapper ">
                <div className="u-grid-xlrg__col2">&nbsp;</div>
                <div className="u-grid-xlrg__col7 u-grid-xxlrg__col5 staking__col">
                  <header className="staking__intro__header staking__title--large">
                    <div className="staking__intro__header__content">
                      <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                        01
                      </div>
                      <h2 className="u-type--gamma u-type--upper u-type--weight--thin">
                        Learn more about
                        <br />
                        <span className="u-type--weight--ultra">
                          Tolar staking
                        </span>
                      </h2>
                    </div>
                  </header>
                  <article>
                    <div>
                      <h3 className="staking__question u-type--epsilon u-type--weight--ultra u-type--upper u-type--typography--wide">
                        What are the minimum staking periods?
                      </h3>
                      <p className="staking__answer u-type--zeta u-opacity--60">
                        Minimum staking period is 12 months, and maximum staking
                        period at this time is 24 months.
                      </p>
                    </div>

                    <div>
                      <h3 className="staking__question u-type--epsilon u-type--weight--ultra u-type--upper u-type--typography--wide">
                        Does my computer have to always be connected to stake?
                      </h3>
                      <p className="staking__answer u-type--zeta u-opacity--60">
                        Not at all. Staking happens on-chain, so it’s completely
                        independent from your computer, mobile device or wallet.
                        Once you have staked your tokens, you can safely quit
                        your wallet software, or shut down your computer.
                      </p>
                    </div>

                    <div>
                      <h3 className="staking__question u-type--epsilon u-type--weight--ultra u-type--upper u-type--typography--wide">
                        Can I stake multiple times from same address?
                      </h3>
                      <p className="staking__answer u-type--zeta u-opacity--60">
                        Yes, you can stake as many times as you like. However,
                        since APR is bigger for bigger amounts, and
                        sending/withdrawing tokens consumes gas, it's generally
                        in your interest to do it in one bigger transaction as
                        opposed to multiple smaller ones.
                      </p>
                    </div>

                    <div>
                      <h3 className="staking__question u-type--epsilon u-type--weight--ultra u-type--upper u-type--typography--wide">
                        Are my tokens safe when staking?
                      </h3>
                      <p className="staking__answer u-type--zeta u-opacity--60">
                        Yes. We are guaranteeing for safety of your tokens and
                        that at the end of your staking period you’ll receive
                        back your tokens and staking reward.
                      </p>
                    </div>

                    <div>
                      <h3 className="staking__question u-type--epsilon u-type--weight--ultra u-type--upper u-type--typography--wide">
                        Can I use my Tolars while staking?
                      </h3>
                      <p className="staking__answer u-type--zeta u-opacity--60">
                        No, you cannot use your Tolars while staking. To
                        withdraw them, you have to wait for lockup period to
                        pass first.
                      </p>
                    </div>
                  </article>
                </div>
                <div className="u-grid-xlrg__col3 u-grid-xxlrg__col5 u-type--right staking__sticky-icon">
                  <img
                    alt=""
                    className="u-display--ib"
                    src={images.iconQuestionMark}
                  />
                </div>
              </div>
            </section>
          </ScrollableAnchor>

          <ScrollableAnchor id={'rewards'}>
            <section className="staking__section--bottom js-section-staking section--light">
              <div className="u-grid wrapper staking__wrapper">
                <div className="u-grid-xlrg__col2">&nbsp;</div>
                <div className="u-grid-xlrg__col10">
                  <header className="staking__intro__header staking__title">
                    <div className="staking__intro__header__content">
                      <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                        02
                      </div>
                      <h2 className="u-type--gamma u-type--upper u-type--weight--ultra">
                        Rewards
                      </h2>
                    </div>
                  </header>

                  <article className="u-grid">
                    <div className="u-grid-med__col12 u-grid-lrg__col6 staking__col staking__rewards-col">
                      <p className="u-opacity--60 staking__rewards-row">
                        You can stake different amount of TOL, for either 12 or
                        24 months. Annual percentage rates are shown here.
                      </p>

                      <p className="u-opacity--40 staking__rewards-info">
                        *So, for example that means if you stake 250,000 TOL for
                        12 months, after the lockup is done, you can withdraw
                        265,000 TOL back.
                      </p>
                    </div>

                    <div className="stake-rewards__column u-grid-med__col6 u-grid-lrg__col3  staking__col">
                      <div className="u-spc-bottom--lrg u-type--typography--compressed u-type--micro u-type--upper u-opacity--50">
                        12 months
                      </div>
                      <div className="stake-rewards__item">
                        <img
                          alt=""
                          className="u-spc-bottom--sml"
                          src={images.iconCircleGraph}
                        />
                        <div className="u-spc-bottom--tny u-type--typography--wide u-type--gamma u-type--upper u-type--weight--ultra">
                          8,00 %
                        </div>
                        <div className="u-type--typography--wide u-type--eta u-opacity--60">
                          &gt;500,000 TOL
                        </div>
                      </div>

                      <div className="stake-rewards__item">
                        <img
                          alt=""
                          className="u-spc-bottom--sml"
                          src={images.iconCircleGraph}
                        />
                        <div className="u-spc-bottom--tny u-type--typography--wide u-type--gamma u-type--upper u-type--weight--ultra">
                          6,00 %
                        </div>
                        <div className="u-type--typography--wide u-type--eta u-opacity--60">
                          100,000 TOL - 500,000 TOL
                        </div>
                      </div>

                      <div className="stake-rewards__item">
                        <img
                          alt=""
                          className="u-spc-bottom--sml"
                          src={images.iconCircleGraph}
                        />
                        <div className="u-spc-bottom--tny u-type--typography--wide u-type--gamma u-type--upper u-type--weight--ultra">
                          5,00 %
                        </div>
                        <div className="u-type--typography--wide u-type--eta u-opacity--60">
                          1,000 TOL - 100,000 TOL
                        </div>
                      </div>
                    </div>

                    <div className="stake-rewards__column u-grid-med__col6 u-grid-lrg__col3">
                      <div className="u-spc-bottom--lrg u-type--typography--compressed u-type--micro u-type--upper u-opacity--50">
                        24 months
                      </div>
                      <div className="stake-rewards__item">
                        <img
                          alt=""
                          className="u-spc-bottom--sml"
                          src={images.iconCircleGraph}
                        />
                        <div className="u-spc-bottom--tny u-type--typography--wide u-type--gamma u-type--upper u-type--weight--ultra">
                          10,00 %
                        </div>
                        <div className="u-type--typography--wide u-type--eta u-opacity--60">
                          &gt;500,000 TOL
                        </div>
                      </div>

                      <div className="stake-rewards__item">
                        <img
                          alt=""
                          className="u-spc-bottom--sml"
                          src={images.iconCircleGraph}
                        />
                        <div className="u-spc-bottom--tny u-type--typography--wide u-type--gamma u-type--upper u-type--weight--ultra">
                          8,00 %
                        </div>
                        <div className="u-type--typography--wide u-type--eta u-opacity--60">
                          100,000 TOL - 500,000 TOL
                        </div>
                      </div>

                      <div className="stake-rewards__item">
                        <img
                          alt=""
                          className="u-spc-bottom--sml"
                          src={images.iconCircleGraph}
                        />
                        <div className="u-spc-bottom--tny u-type--typography--wide u-type--gamma u-type--upper u-type--weight--ultra">
                          7,00 %
                        </div>
                        <div className="u-type--typography--wide u-type--eta u-opacity--60">
                          1,000 TOL - 100,000 TOL
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </section>
          </ScrollableAnchor>

          <ScrollableAnchor id={'how-to-join'}>
            <section className="staking__section staking__join section--dark">
              <div className="u-grid wrapper staking__wrapper ">
                <div className="u-grid-xlrg__col2">&nbsp;</div>
                <div className="u-grid-xlrg__col10">
                  <header className="staking__title">
                    <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                      01
                    </div>
                    <h2 className="u-type--gamma u-type--upper u-type--weight--thin">
                      How to join
                      <br />
                      <span className="u-type--weight--ultra">
                        Tolar staking
                      </span>
                    </h2>
                  </header>

                  <article className="staking__title--large ">
                    <h3 className="staking__join-title u-type--epsilon u-type--weight--ultra u-type--upper u-type--typography--wide">
                      Native <br /> tolar coins
                    </h3>
                    <div className="u-grid">
                      <div className="u-grid-lrg__col6 staking__join-col">
                        <p className="u-spc-bottom--med u-opacity--60">
                          To stake native TOL for 12 months, send wanted amount
                          to:
                        </p>
                        <p className="staking__join-token">
                          <a href="#" onClick={this.copyToClipboard}>
                            5453f9b919442ac5e8b75b1cb233e0fe611e01fb8a0c8ede63
                          </a>
                        </p>
                      </div>

                      <div className="u-grid-lrg__col6">
                        <p className="u-spc-bottom--med u-opacity--60">
                          To stake native TOL for 24 months, send wanted amount
                          to:
                        </p>
                        <p className="staking__join-token">
                          <a href="#" onClick={this.copyToClipboard}>
                            54ff06f67f80025679521e1c2f62ee028798b2672bd1c408db
                          </a>
                        </p>
                      </div>
                    </div>
                  </article>

                  <article>
                    <h3 className="staking__join-title u-type--epsilon u-type--weight--ultra u-type--upper u-type--typography--wide">
                      Erc20 <br /> tokens
                    </h3>
                    <div className="u-grid">
                      <div className="u-spc-bottom--lrg u-grid-xlrg__col6 u-opacity--60 staking__join-col">
                        To help our users do the swap, if you didn’t do the
                        swap, you can now stake ERC20 tokens, and when staking
                        period expires, you’ll get swapped tokens back. In order
                        to do this, you’ll need to create equivalent native TOL
                        address first. You can find the guide
                        <a
                          href="https://medium.com/@TolarHashNET/tolar-staking-service-bbca59760e75"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          here{' '}
                        </a>
                        on how to create equivalent native TOL address from your
                        Ethereum ERC20 token address.
                      </div>
                    </div>
                    <div className="u-grid">
                      <div className="u-grid-lrg__col6 staking__join-col">
                        <p className="u-spc-bottom--med u-opacity--60">
                          To stake and swap ERC20 TOL for 12 months send wanted
                          amount here:
                        </p>
                        <p>
                          <a href="#" onClick={this.copyToClipboard}>
                            0x0F99F47E8BAf44a8aaa9b7f0bC4eF7DbcD91Ac0B
                          </a>
                        </p>
                      </div>

                      <div className="u-grid-lrg__col6">
                        <p className="u-spc-bottom--med u-opacity--60">
                          To stake and swap ERC20 TOL for 24 months send wanted
                          amount here:
                        </p>
                        <p>
                          <a href="#" onClick={this.copyToClipboard}>
                            0xB368b6e7B2dD3B0DBaF0f669107e7b51FC43115d
                          </a>
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </section>
          </ScrollableAnchor>
        </main>
      </>
    );
  }
}
