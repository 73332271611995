import { Switch, Router, Route } from 'react-router-dom';
import { Layout } from 'main/components';
import createHistory from 'history/createBrowserHistory';
import { routes, AppRoute } from '../constants';
import ScrollToTop from 'main/components/ScrollToTop';

const history = createHistory();

export const Routing = () => (
  <Router history={history}>
    <ScrollToTop>
      <Layout>
        <Switch>
          {routes.map((route: AppRoute, index: number) => (
            <Route
              key={route.key ? route.key : index}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </Layout>
    </ScrollToTop>
  </Router>
);
