import anime from 'animejs';
import React, { useEffect } from 'react';

import { ReactComponent as EnterpriseContact } from 'main/assets/icons/enterprise-contact.svg';
import { ReactComponent as EnterpriseEngagement } from 'main/assets/icons/enterprise-engagement.svg';
import { ReactComponent as EnterpriseIdentity } from 'main/assets/icons/enterprise-identity.svg';
import { ReactComponent as EnterpriseLogistics } from 'main/assets/icons/enterprise-logistics.svg';
import { ReactComponent as EnterprisePayment } from 'main/assets/icons/enterprise-payment.svg';

export const Enterprise: React.FC = () => {
  useEffect(() => {
    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation.add({
      targets: '.contentpage__title',
      opacity: [0, 1],
      translateY: [-40, 0],
      duration: 800,
      delay: 800,
      easing: 'easeOutQuint',
    });
  }, []);

  return (
    <section className="contentpage">
      <header className="contentpage__header section--dark">
        <div className="wrapper">
          <div className="contentpage__title contentpage__title--left-aligned">
            <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
              Enterprise
            </p>
            <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--xlrg">
              Tolar mainnet for enterprise
            </h1>
            <h2 className="u-type--delta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-spc-top--xlrg">
              Blockchain applications help businesses:
            </h2>

            <ul className="u-spc-top--lrg list list--icon">
              <li className="u-type--zeta u-type--thin">
                Increase trust and reduce the cost of coordination between
                business parties
              </li>
              <li className="u-type--zeta u-type--thin">
                Improve business network accountability and operational
                efficiency
              </li>
              <li className="u-type--zeta u-type--thin">
                Build new business models and value creation opportunities
              </li>
              <li className="u-type--zeta u-type--thin">
                Competitively future-proof their organization
              </li>
              <li className="u-type--zeta u-type--thin">
                Enterprise blockchain applications can be built on the public
                permissionless Tolar mainnet, or on private blockchains that are
                based on Tolar HashNET technology.
              </li>
            </ul>
            <p className="u-spc-top--lrg u-opacity--60">
              Find more about private Enterprise Tolar chains.
            </p>

            <h2 className="u-type--delta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-spc-top--xlrg">
              Public vs private Tolar HashNET
            </h2>
            <p className="u-spc-top--lrg u-opacity--60">
              There is only one public Tolar HashNET mainnet. Applications that
              are built on the mainnet are able to interoperate, similarly to
              how applications built on the Internet can connect to each other,
              leveraging the full potential of decentralized blockchain.
            </p>
            <p className="u-spc-top--lrg u-opacity--60">
              Many businesses and consortia have deployed private, permissioned
              blockchains for specific applications based on Tolar HashNET
              technology.
            </p>

            <h2 className="u-type--delta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-spc-top--xlrg">
              Have questions?
            </h2>
            <p className="u-spc-top--lrg u-opacity--60">
              Get in touch with Tolar HashNET team for inquiries on setting up a
              private network.
            </p>
            <a
              href="mailto:info@tolar.io"
              className="btn btn--primary btn--lrg home__header__button u-spc-top--lrg"
            >
              Contact us
            </a>
          </div>
        </div>
      </header>
      <main className="section section--light">
        <div className="wrapper">
          <h2 className="u-type--beta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-spc-top--xlrg">
            Time for trust
          </h2>
          <p className="u-spc-top--lrg u-opacity--60">
            There is an opportunity for blockchain technology across every
            industry: from healthcare, government and public services to
            manufacturing, finance, logistics and retail.
          </p>
          <p className="u-spc-top--lrg u-opacity--60">
            Majority of enterprises will use blockchain in some form by 2025.
          </p>

          <div className="u-spc-top--xlrg enterprise__item">
            <div className="enterprise__item__content">
              <span className="u-type--complementary">
                <strong>01</strong>
              </span>
              <h3 className="u-type--delta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                Payments and Finance
              </h3>
              <ul className="u-spc-top--lrg list list--icon">
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Clearence and Settlement Systems by using Stable Coins
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Fundraising with Security Tokens (Equity, Debt or Real Asset
                  tokens)
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Loans and Credits by using Smart Contracts
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Trade Finance – using blockchain to support cross-border trade
                  transactions
                </li>
              </ul>
            </div>
            <EnterprisePayment className="enterprise__item__icon" />
          </div>
          <div className="u-spc-top--xlrg enterprise__item">
            <div className="enterprise__item__content">
              <span className="u-type--complementary">
                <strong>02</strong>
              </span>
              <h3 className="u-type--delta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                Identity Management
              </h3>
              <ul className="u-spc-top--lrg list list--icon">
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Providing digital identity by safeguarding valuable personal
                  information online
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Use of blockchain network to store digital certificates in
                  education – enabling enterprises to quickly verify information
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Manage patient data and provide interoperability between
                  healthcare providers
                </li>
              </ul>
            </div>
            <EnterpriseIdentity className="enterprise__item__icon" />
          </div>
          <div className="u-spc-top--xlrg enterprise__item">
            <div className="enterprise__item__content">
              <span className="u-type--complementary">
                <strong>03</strong>
              </span>
              <h3 className="u-type--delta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                Logistics and Transportation
              </h3>
              <ul className="u-spc-top--lrg list list--icon">
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Verify source of goods and their movement in any step using
                  public or private blockchains between enterprises
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Ensure transparency in every supply chain to build customer
                  trust and provide additional safety measures
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Using IoT and mobile technologies for real- time logistics
                  tracing and blockchain for exchange of informations
                </li>
              </ul>
            </div>
            <EnterpriseLogistics className="enterprise__item__icon" />
          </div>
          <div className="u-spc-top--xlrg enterprise__item">
            <div className="enterprise__item__content">
              <span className="u-type--complementary">
                <strong>04</strong>
              </span>
              <h3 className="u-type--delta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                Customer Engagement
              </h3>
              <ul className="u-spc-top--lrg list list--icon">
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Use of blockchain for customer loyalty programs, enabling
                  interoperability by providing universal digital wallet
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Enable secure sharing of customer data on the blockchain
                  taking in the consideration GDPR and security protocols
                </li>
              </ul>
            </div>
            <EnterpriseEngagement className="enterprise__item__icon" />
          </div>
          <div className="u-spc-top--xlrg enterprise__item">
            <div className="enterprise__item__content">
              <span className="u-type--complementary">
                <strong>05</strong>
              </span>
              <h3 className="u-type--delta u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                Contracts and Dispute Resolution
              </h3>
              <ul className="u-spc-top--lrg list list--icon">
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Use of Smart Contract for to improve the flow of commercial
                  agreements
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Enforceability And Jurisdiction between different countries
                  using blockchain
                </li>
                <li className="u-opacity--60 u-type--zeta u-type--thin">
                  Crowdsourcing and voting using public blockchain networks
                </li>
              </ul>
            </div>
            <EnterpriseContact className="enterprise__item__icon" />
          </div>
        </div>
      </main>
    </section>
  );
};
