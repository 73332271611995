import CoinGecko from 'coingecko-api';
import { ChangeEvent, useEffect, useState } from 'react';

/** Official types are missing a lot of stuff. */
const client: any = new CoinGecko();

export const CurrentValue: React.FC = () => {
  const [currentValueUSD, setCurrentValueUSD] = useState<number>();
  const [currentValueEUR, setCurrentValueEUR] = useState<number>();
  const [btcValue, setBtcValue] = useState<number>();
  const [btcDailyChangePercentage, setBtcDailyChangePercentage] =
    useState<number>();
  const [currency, setCurrency] = useState<'eur' | 'usd'>('eur');

  useEffect(() => {
    client.coins.fetch('tolar', {}).then((response: any) => {
      setCurrentValueEUR(response.data.market_data.current_price.eur);
      setCurrentValueUSD(response.data.market_data.current_price.usd);
      setBtcValue(response.data.market_data.current_price.btc);
      setBtcValue(response.data.market_data.current_price.btc);
      setBtcDailyChangePercentage(
        response.data.market_data.price_change_percentage_24h_in_currency.btc,
      );
    });
  }, []);

  function handleSelectCurrency(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    setCurrency(value as 'eur' | 'usd');
  }

  function renderDailyChange() {
    if (btcDailyChangePercentage && btcDailyChangePercentage > 0) {
      return (
        <span className="u-type--success">
          ({btcDailyChangePercentage?.toFixed(2)} %)
        </span>
      );
    }

    return (
      <span className="u-type--warning">
        ({btcDailyChangePercentage?.toFixed(2)} %)
      </span>
    );
  }

  if (!currentValueEUR || !currentValueUSD) {
    return <p className="u-type--eta u-opacity--50">No data.</p>;
  }

  return (
    <>
      <p className="u-type--eta u-type--upper u-type--spacing--wide u-opacity--40  u-spc-top--lrg">
        Current price
      </p>
      <div className="u-type--beta u-type--upper u-type--negative">
        <p className="u-type--typography--wide u-spc-top--med u-spc-bottom--med">
          {currency === 'eur' ? `€${currentValueEUR}` : `$${currentValueUSD}`}
          <select
            className="input input--select u-type--typography--wide"
            onChange={handleSelectCurrency}
            name="currency"
            id="currency"
          >
            <option selected={currency === 'eur'} value="eur">
              eur
            </option>
            <option selected={currency === 'usd'} value="usd">
              usd
            </option>
          </select>
        </p>
      </div>
      <p className="u-type--upper u-type--negative">
        @{btcValue?.toFixed(12)} BTC {renderDailyChange()}
      </p>
    </>
  );
};
