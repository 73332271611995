import { format } from 'date-fns';
import { ReactComponent as IconCaretCircle } from 'main/assets/icons/icon-caret-circle.svg';

interface BlogPost {
  blogData:
    | {
        title: string;
        pubDate: string;
        link: string;
        guid: string;
        author: string;
        thumbnail: string;
        description: string;
        categories: string;
      }
    | undefined;
}

export const FeaturedArticle: React.FC<BlogPost> = ({ blogData }) => {
  if (!blogData) {
    return null;
  }

  const { pubDate, title, thumbnail, link } = blogData;

  return (
    <div className="blog__featured">
      <div className="blog__featured__info">
        <div>
          <p className="u-type--primary u-type--upper u-type--nano blog__featured__date">
            {/* Safari has an issue where it can't create date in a 'yyyy-mm-dd' format
             * it needs to be 'yyyy/mm/dd'. So replace function below fixes that issue.
             * Fix is explained here https://stackoverflow.com/questions/4310953/invalid-date-in-safari
             */}
            {format(new Date(pubDate.replace(/-/g, '/')), 'LLL dd, y')}
          </p>
          <a href={link} className="blog__featured__title">
            {title}
          </a>
          <a href={link}>
            <IconCaretCircle className="u-spc-top--lrg blog__cta" />
          </a>
        </div>
      </div>
      <a href={link} className="blog__latest-image">
        <img className="blog__latest-image__image" src={thumbnail} alt="" />
      </a>
    </div>
  );
};
