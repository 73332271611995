import anime from 'animejs';
import { images } from 'main/assets';
import { Modal, Portal, SEO } from 'main/components';
import { Nodes } from 'main/models/Nodes';
import { CurrentValue, ValueToday } from 'modules/stats';
import React from 'react';
import ScrollMagic from 'scrollmagic';
import ScrollableAnchor from 'react-scrollable-anchor';

interface PageState {
  isModalOpen: boolean;
  nodes: Nodes;
  error: string;
}

export class Hashnet extends React.Component<PageState> {
  state = {
    isModalOpen: false,
    error: '',
  };

  // refs for getting the height of an element on resize
  private hashnetRef: React.RefObject<HTMLElement>;
  private functionalitiesRef: React.RefObject<HTMLElement>;

  // SEO props
  private seoProps = {
    SEOTitle: 'Tolar - Hashnet',
    SEODescription:
      'Tolar is a community governed crypto-currency featuring scalable, fast, secure, and fair transactions.',
    SEOUrl: 'dummyUrl',
  };

  // ScrollMagic scenes
  private hashnetScene = new ScrollMagic.Scene();
  private functionalitiesScene = new ScrollMagic.Scene();

  // ScrollMagic controller
  private scrollMagicController = new ScrollMagic.Controller({
    globalSceneOptions: { triggerHook: '0' },
  });

  constructor(props: any) {
    super(props);
    this.hashnetRef = React.createRef();
    this.functionalitiesRef = React.createRef();
  }

  handleSectionResize = () => {
    this.hashnetScene.duration(this.hashnetRef.current!.clientHeight);
    this.functionalitiesScene.duration(
      this.functionalitiesRef.current!.clientHeight,
    );
  };

  // to finish this we need css classes on section elements
  setScrollMagicScenes = () => {
    this.hashnetScene
      .triggerElement('.js-section-hashnet')
      .setClassToggle('.container', 'dark')
      .duration(this.hashnetRef.current!.clientHeight)
      // .addIndicators()
      .addTo(this.scrollMagicController);

    this.functionalitiesScene
      .triggerElement('.js-section-functionalities')
      .setClassToggle('.container', 'light')
      .duration(this.functionalitiesRef.current!.clientHeight)
      // .addIndicators()
      .addTo(this.scrollMagicController);
  };

  componentDidMount() {
    this.setScrollMagicScenes();
    window.addEventListener('resize', this.handleSectionResize);

    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.contentpage__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.hashnet__header__content',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.hashnet__header__button',
        opacity: [0, 1],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.hashnet__valuation',
        opacity: [0, 1],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSectionResize);
    this.scrollMagicController.destroy(true);
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  render() {
    const { isModalOpen } = this.state;

    return (
      <section
        className="contentpage hashnet js-section-hashnet"
        ref={this.hashnetRef}
      >
        {isModalOpen && (
          <Portal>
            <Modal onClose={this.toggleModal}>
              <iframe
                title="Youtube"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fO58E9Y4AOY?rel=0&amp;controls=0&amp;showinfo=0"
                frameBorder="0"
              />
            </Modal>
          </Portal>
        )}
        <SEO {...this.seoProps} />
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .u-bg--video { opacity: 0.2 }
          .u-bg--deco { display: none }
          .u-bg--space { display: none }
        `,
          }}
        />
        <header className="contentpage__header section--dark">
          <div className="wrapper wrapper--medium">
            <div className="hashnet__header__image">
              <img
                src={images.illustrationHashnetAnimation}
                alt="Hashnet - a transaction superhighway"
              />
            </div>
            <div className="contentpage__title contentpage__title--left-aligned">
              <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
                Hashnet
              </p>
              <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                A transaction superhighway
              </h1>
            </div>
            <div className="hashnet__header__content u-spc-bottom--med u-opacity--60 u-type--zeta">
              HashNET is a scalable, fast, secure, and fair decentralized-
              beyond blockchain project, leveraging Distributed Ledger
              Technology (DLT) and consensus algorithm which keeps all positive
              characteristics of a blockchain technology while increasing
              throughput and lowering time to finality.
            </div>
            <div className="hashnet__header__button hashnet__header__button--alt">
              <button
                className="btn btn--primary btn--lrg u-spc-right--lrg"
                onClick={this.toggleModal}
              >
                View demo presentation
              </button>
            </div>
            <section className="hashnet__valuation">
              <h3 className="u-type--epsilon u-type--upper u-spc-top--xlrg">
                <strong>Tolar market valuation:</strong>
              </h3>

              <CurrentValue />

              <ValueToday />

              <h3 className="u-type--epsilon u-type--upper u-spc-top--xlrg hashnet__exchanges">
                <strong>
                  Currently you can get TOL tokens on{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://coinmarketcap.com/currencies/tolar/markets/"
                  >
                    listed exchanges
                  </a>
                </strong>
              </h3>
            </section>
          </div>
        </header>
        <main
          ref={this.functionalitiesRef}
          className="js-section-functionalities"
        >
          <section className="hashnet__status section--light">
            <div className="wrapper wrapper--medium">
              <div className="hashnet__status__section">
                <h2 className="u-type--beta u-type--upper hashnet__status__section-title">
                  status updates - <strong>Core</strong>
                </h2>

                <h3 className="u-type--delta">
                  <strong>CURRENT</strong>
                </h3>
                <ul className="list list--icon u-spc-bottom--lrg">
                  <li>Tolar HashNET v1.1. mainnet - performance upgrade</li>
                  <li>
                    Gossip protocol upgrade with better disk space utilization
                  </li>
                  <li>
                    Support for internal transactions (contract interactions) on
                    blockchain explorer
                  </li>
                </ul>
                <h3 className="u-type--delta">
                  <strong>COMING SOON</strong>
                </h3>
                <ul className="list list--icon u-spc-bottom--lrg">
                  <li>Tolar HashNET v1.2 mainnet</li>
                  <li>Dynamic membership for master nodes</li>
                </ul>
              </div>

              <div className="hashnet__status__section">
                <h2 className="u-type--gamma u-type--upper hashnet__status__section-title">
                  status updates - <strong>PRODUCT</strong>
                </h2>

                <h3 className="u-type--delta">
                  <strong>CURRENT</strong>
                </h3>
                <ul className="list list--icon u-spc-bottom--lrg">
                  <li>
                    Focus was on infrastructure, now shifting to developers and
                    user experience on Tolar HashNET
                  </li>
                </ul>
                <h3 className="u-type--delta">
                  <strong>COMING SOON</strong>
                </h3>
                <ul className="list list--icon u-spc-bottom--lrg">
                  <li>Improving developer user experience</li>
                  <li>Onboard several showcase DAPPs</li>
                </ul>
              </div>
            </div>
          </section>
          <section className="hashnet__features  section--light">
            <ScrollableAnchor id={'features'}>
              <div className="wrapper">
                <div className="hashnet__features__wrapper">
                  <header className="hashnet__features__header">
                    <h1 className="u-type--gamma u-type--upper u-type--weight--thin">
                      <span className="u-type--weight--ultra">Hashnet</span>{' '}
                      functionalities
                    </h1>
                  </header>
                  <div className="hashnet__features__content">
                    <div className="hashnet__features__feature">
                      <div className="hashnet__features__feature__visual">
                        <img
                          src={images.illustrationHashnetScalability}
                          alt="Scalability"
                        />
                      </div>
                      <div className="hashnet__features__feature__content">
                        <div className="u-type--weight--ultra u-type--eta u-type--complementary">
                          01
                        </div>
                        <h1 className="u-type--epsilon u-type--typography--wide u-type--upper u-type--weight--ultra u-spc-bottom--med">
                          Scalability
                        </h1>
                        <p className="u-type--zeta u-opacity--60">
                          HashNET is scalable. Scalability is the biggest
                          problem in the blockchain technology space. Projects
                          like Bitcoin and Ethereum are simply not capable of
                          transferring a large number of blockchian transactions
                          in a short timeframe. HashNET, on the other hand, was
                          built to support over 20,000 transactions per second,
                          compared to Bitcoin, which can only manage 7
                          transactions per second. Also, even with a large
                          increase in number of nodes, HashNET network will be
                          able to process all transactions in a matter of
                          seconds.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="hashnet__features__feature">
                      <div className="hashnet__features__feature__visual">
                        <img
                          src={images.illustrationHashnetSecurity}
                          alt="Security"
                        />
                      </div>
                      <div className="hashnet__features__feature__content">
                        <div className="u-type--weight--ultra u-type--eta u-type--complementary">
                          02
                        </div>
                        <h1 className="u-type--epsilon u-type--typography--wide u-type--upper u-type--weight--ultra u-spc-bottom--med">
                          100% Secure
                        </h1>
                        <p className="u-type--zeta u-opacity--60">
                          HashNET is 100% secure. HashNET can’t be hacked due to
                          the power of distributed algorithm, which is
                          responsible for reaching consensus within the nodes.
                          Also, combining Proof-of-Stake and masternode creates
                          transparency, since the participants are compensated
                          for correct voting.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="hashnet__features__feature">
                      <div className="hashnet__features__feature__visual">
                        <img
                          src={images.illustrationHashnetSpeed}
                          alt="Speed"
                        />
                      </div>
                      <div className="hashnet__features__feature__content">
                        <div className="u-type--weight--ultra u-type--eta u-type--complementary">
                          03
                        </div>
                        <h1 className="u-type--epsilon u-type--typography--wide u-type--upper u-type--weight--ultra u-spc-bottom--med">
                          Speed
                        </h1>
                        <p className="u-type--zeta u-opacity--60">
                          HashNET is fast, because it doesn’t require miners to
                          create a chain of blocks in order to record
                          transactions. This process of creating a chain of
                          blocks is very slow and it consumes a large amount of
                          electricity. HashNET uses Proof-of-Stake algorithm
                          combined with masternode to reach distributed
                          consensus. The process of receiving and recording
                          occurs as soon as transaction takes place, which
                          allows the final confirmation to be ready instantly.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="hashnet__features__feature">
                      <div className="hashnet__features__feature__visual">
                        <img
                          src={images.illustrationHashnetFairness}
                          alt="Fairness"
                        />
                      </div>
                      <div className="hashnet__features__feature__content">
                        <div className="u-type--weight--ultra u-type--eta u-type--complementary">
                          04
                        </div>
                        <h1 className="u-type--epsilon u-type--typography--wide u-type--upper u-type--weight--ultra u-spc-bottom--med">
                          Fairness
                        </h1>
                        <p className="u-type--zeta u-opacity--60">
                          HashNET is fair, because no individual can manipulate
                          the order of transactions. With HashNET, there is no
                          way for the individual to affect the consensus order.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollableAnchor>
          </section>
        </main>
      </section>
    );
  }
}
