import anime from 'animejs';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

interface SuccessMessage {
  beforeLink: string;
  link: string;
  afterLink: string;
}

export const Testnet: React.FC = () => {
  const [tolAddress, setTolAddress] = useState<string>();
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<SuccessMessage>();

  useEffect(() => {
    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation.add({
      targets: '.contentpage__title',
      opacity: [0, 1],
      translateY: [-40, 0],
      duration: 800,
      delay: 800,
      easing: 'easeOutQuint',
    });
  }, []);

  function handleFormSubmit(event: FormEvent<HTMLFormElement>) {
    event.stopPropagation();
    event.preventDefault();

    setError('');
    setSuccess(undefined);

    fetch(`https://faucet.testnet.tolar.io/faucet/${tolAddress}`)
      .then((res) => {
        console.log({ res });
        if (res.status === 400) {
          res
            .text()
            .then(() =>
              setError(
                'Your request failed, please check you entered a valid tolar address or try again later. You can only make one valid request from the faucet in a short period of time.',
              ),
            );
        } else {
          res.text().then((res) =>
            setSuccess({
              beforeLink: `Your request is confirmed, transaction hash is: ${res}. The transaction will be visible at`,
              link: `https://web-explorer.testnet.tolar.io/?query=${res}`,
              afterLink: `once it ends up on the Tolar blockchain.`,
            }),
          );
        }
      })
      .catch((err) => {
        console.log({ err });
        setError(err.message);
      });
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.currentTarget;
    setTolAddress(value);
  }

  return (
    <div className="testnet">
      <div className="wrapper wrapper--medium">
        <header className="section--dark">
          <div className="contentpage__title contentpage__title--left-aligned">
            <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
              Hashnet
            </p>
            <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
              Testnet faucet
            </h1>
            <h2 className="u-type--secondary u-opacity--70 u-type--epsilon">
              Get testnet TOL tokens:
            </h2>
            <ul className="u-spc-top--lrg list list--icon">
              <li>In order to deploy new contract</li>
              <li>Interact with already deployed contracts</li>
              <li>Send funds between addresses</li>
              <li>Generally see how to use the wallets</li>
              <li>Interact with DAPPs on the Testnet</li>
            </ul>

            <form
              onSubmit={handleFormSubmit}
              method="post"
              className="newsletter__form u-spc-top--xlrg"
              noValidate
            >
              <input
                type="text"
                placeholder="Your TOL address.."
                className="testnet__input input input--med"
                value={tolAddress}
                name="tolAddress"
                onChange={handleChange}
              />
              <button type="submit" className="btn btn--primary btn--med">
                Request tokens
              </button>
            </form>
            {error && (
              <p className="u-spc-top--med u-color--warning">{error}</p>
            )}
            {success && (
              <p className="u-spc-top--med u-color--success">
                {success.beforeLink}
                <a
                  href={success.link}
                  className="u-spc-left--tny u-spc-right--tny testnet__success-link"
                >
                  {success.link}
                </a>
                {success.afterLink}
              </p>
            )}

            <h2 className="u-type--secondary u-opacity--70 u-type--eta u-spc-top--xlrg">
              To get mainnet TOL tokens:
            </h2>
            <a
              className="u-type--shorten"
              href="https://coinmarketcap.com/currencies/tolar/markets/"
            >
              https://coinmarketcap.com/currencies/tolar/markets/
            </a>
            <p className="u-spc-top--xlrg">More exchanges coming soon!</p>
          </div>
        </header>
      </div>
    </div>
  );
};
