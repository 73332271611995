import anime from 'animejs';
import { images, videos } from 'main/assets';
import { Navigation } from 'modules/navigation';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Footer, Newsletter, SocialBar, ToTopBar } from '.';

class Layout extends React.Component<RouteComponentProps<any>> {
  handleNextSection = () => {
    window.scrollBy({ top: window.innerHeight - 200, behavior: 'smooth' });
  };

  hideBackgroundImage = () => {
    // current scroll position
    const scrollPosition = window.scrollY;
    // element which will get the class
    const imageElement = document.getElementsByClassName('u-bg--deco')[0];
    const videoElement = document.getElementsByClassName('u-bg--video')[0];
    // hide after px
    const scrollHeight = 120;

    const addClassOnScroll = () => {
      imageElement!.classList.add('away-from-top');
      videoElement!.classList.add('away-from-top');
    };
    const removeClassOnScroll = () => {
      imageElement!.classList.remove('away-from-top');
      videoElement!.classList.remove('away-from-top');
    };

    if (scrollPosition >= scrollHeight) {
      addClassOnScroll();
    } else {
      removeClassOnScroll();
    }
  };

  componentDidMount() {
    anime({
      targets: '.container__content',
      opacity: [0, 1],
      duration: 400,
      easing: 'easeInQuad',
    });

    this.animateOverlay();

    window.addEventListener('scroll', this.hideBackgroundImage);

    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.hero__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.hero__claim',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.home__header__button',
        opacity: [0, 1],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.hideBackgroundImage);
  }

  UNSAFE_componentWillUpdate(nextProps: any) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.animateOverlay();
    }
  }

  animateOverlay = () => {
    const overlayAnimation = anime.timeline();

    overlayAnimation
      .add({
        targets: '.container__content',
        opacity: [1, 0],
        duration: 1,
        easing: 'easeInQuad',
      })
      .add({
        targets: '.header',
        translateY: ['0', '-110%'],
        duration: 600,
        easing: 'easeInQuad',
        offset: '-=600',
      })
      .add({
        targets: '.container__overlay',
        translateX: ['-100%', '0'],
        duration: 300,
        easing: 'easeInQuad',
      })
      .add({
        targets: '.container__overlay',
        translateX: ['0', '0'],
        duration: 300,
        easing: 'easeOutQuad',
      })
      .add({
        targets: '.container__overlay',
        translateX: ['0', '100%'],
        duration: 300,
        easing: 'easeOutQuad',
      })
      .add({
        targets: '.container__content',
        opacity: [0, 1],
        duration: 100,
        easing: 'easeInQuad',
        offset: '-=600',
      })
      .add({
        targets: '.header',
        translateY: ['-100%', '0'],
        duration: 800,
        easing: 'easeInQuad',
        offset: '-=600',
      });
  };

  render() {
    const { children } = this.props;
    const hideToTopBar =
      this.props.location.pathname.includes('/download') ||
      this.props.location.pathname.includes('/taquin-plugin');
    const showWidget = this.props.location.pathname === '/';

    return (
      <div className="container">
        <Navigation />
        <div className="container__content">
          <div className="u-bg--top">
            <SocialBar />
            {!hideToTopBar && (
              <ToTopBar nextSectionCallback={this.handleNextSection} />
            )}
            <div className="container__children">{children}</div>
            <section
              className={`js-section-widget  section--light ${
                !showWidget && 'u-display--n'
              }`}
            >
              <div
                id="widget"
                className="wrapper features__content-wrapper u-spc-bottom--none"
              >
                <div className="features__widget-wrapper">
                  <h2 className="features__widget__title u-type--epsilon u-type--upper u-type--weight--ultra">
                    <span>
                      TOLAR MARKET
                      <span>VALUATION</span>
                    </span>
                  </h2>
                  <div className="features__widget">
                    <div
                      id="crypto-widget-CoinTicker"
                      data-design="classic"
                      data-coin-ids="1113"
                    ></div>
                  </div>
                </div>
              </div>
            </section>
            <Newsletter />
            <Footer />
          </div>
        </div>
        <div className="u-bg--deco">
          <img src={images.dotsCircle} alt="Background dots" />
        </div>
        <div className="u-bg--space" />
        <div className="u-bg--video">
          <video width="1300" height="1200" autoPlay muted loop>
            <source src={videos.videoBlobDark} type="video/mp4" />
          </video>
        </div>
      </div>
    );
  }
}

export default withRouter(Layout);
