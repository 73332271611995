import anime from 'animejs';
import { BlogArticle, FeaturedArticle } from 'modules/blog';
import React, { useEffect, useState } from 'react';

interface BlogPost {
  title: string;
  pubDate: string;
  link: string;
  guid: string;
  author: string;
  thumbnail: string;
  description: string;
  categories: string;
}

export const Blog: React.FC = () => {
  const [featuredPost, setFeaturedPost] = useState<BlogPost>();
  const [posts, setPosts] = useState<BlogPost[]>();

  useEffect(() => {
    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.blog__featured',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.blog__rest',
        opacity: [0, 1],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }, []);

  useEffect(() => {
    fetch(
      'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/tolarhashnetdev',
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.items?.length) {
          setFeaturedPost(res.items[0]);

          if (res.items.length > 1) {
            // Remove first, it's the featured.
            res.items.shift();
            setPosts(res.items);
          }
        }
      });
  }, []);

  return (
    <div className="wrapper">
      <div className="blog">
        <FeaturedArticle blogData={featuredPost} />
        <div className="blog__rest">
          <h4 className="u-type--negative u-type--large u-spc-bottom--lrg">
            Latest posts
          </h4>
          <div className="blog__grid">
            {posts?.length ? (
              posts.map((item) => (
                <BlogArticle key={item.guid} blogData={item} />
              ))
            ) : (
              <p className="u-opacity--50">No other posts yet.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
