import anime from 'animejs';
import { DownloadButton } from 'main/components';
import React, { useEffect } from 'react';

import { ReactComponent as IconDeveloper } from 'main/assets/icons/icon-taquin-developer.svg';
import { ReactComponent as IconDownload } from 'main/assets/icons/icon-taquin-download.svg';
import { ReactComponent as IconExtension } from 'main/assets/icons/icon-taquin-extensions.svg';
import { ReactComponent as IconLoad } from 'main/assets/icons/icon-taquin-load.svg';
import { ReactComponent as IconPlugin } from 'main/assets/icons/icon-taquin-plugin.svg';

import ChromeLogo from 'main/assets/images/logos/logo-chrome.svg';
import MacLogo from 'main/assets/images/logos/logo-apple.svg';
import LinuxLogo from 'main/assets/images/logos/logo-linux.svg';
import WindowsLogo from 'main/assets/images/logos/logo-windows.svg';

const logos = {
  Chrome: ChromeLogo,
  Mac: MacLogo,
  Linux: LinuxLogo,
  Windows: WindowsLogo,
};

import { downloadInfo } from 'main/consts';

export const Taquin: React.FC = () => {
  const guiWalletDownloadInfo = downloadInfo.find(
    (info) => info.type.toLowerCase() === 'wallet',
  );

  useEffect(() => {
    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation.add({
      targets: '.contentpage__title',
      opacity: [0, 1],
      translateY: [-40, 0],
      duration: 800,
      delay: 800,
      easing: 'easeOutQuint',
    });
  }, []);

  return (
    <section className="taquin">
      <div className="wrapper wrapper">
        <header className="taquin__header">
          <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
            Hashnet
          </p>
          <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
            Taquin plugin
          </h1>
        </header>
        <div className="taquin__body">
          <div className="taquin__steps">
            <p className="u-type--secondary u-opacity--70 u-type--zeta u-spc-bottom--xlrg">
              Install Taquin wallet (currently developer mode only) chrome
              plugin following instalation steps:
            </p>

            <div className="taquin__step">
              <IconDownload />
              <p className="u-type--secondary u-opacity--70 u-type--eta">
                Download the Taquin wallet
              </p>
            </div>
            <div className="taquin__step">
              <IconExtension />
              <p className="u-type--secondary u-opacity--70 u-type--eta">
                Go to chrome://extensions
              </p>
            </div>
            <div className="taquin__step">
              <IconDeveloper />
              <p className="u-type--secondary u-opacity--70 u-type--eta">
                Enable developer mode
              </p>
            </div>
            <div className="taquin__step">
              <IconLoad />
              <p className="u-type--secondary u-opacity--70 u-type--eta">
                Click Load unpacked and locate the downloaded folder
              </p>
            </div>
            <div className="taquin__step">
              <IconPlugin />
              <p className="u-type--secondary u-opacity--70 u-type--eta">
                Enable Taquin plugin (if disabled)
              </p>
            </div>
          </div>
          <div className="taquin__download">
            <p className="u-type--secondary u-opacity--70 u-type--eta">
              Taquin App on Google plugin store coming soon - currently download
              only the developer version
            </p>
            <p className="u-spc-top--lrg u-type--secondary u-opacity--70 u-type--eta">
              To download Taquin plugin, click the button down below.
            </p>

            <a
              href="https://github.com/Tolar-HashNET/Tolar-mainnet-release/releases/download/v1.1.00/taquin-chrome-1.1.zip"
              download
              className="btn btn--sml btn--primary u-spc-top--lrg"
            >
              Download
            </a>
          </div>
        </div>
        {guiWalletDownloadInfo && (
          <div className="download__wrapper">
            <section className="download__testnet">
              <p className="u-type--gamma u-spc-bottom--med u-type--center">
                {guiWalletDownloadInfo.title}
              </p>
              {guiWalletDownloadInfo.downloads?.map((download, indexDown) => {
                const thisLogo = logos[download.for];

                return (
                  <div className="download__os" key={indexDown}>
                    <DownloadButton
                      logo={thisLogo}
                      link={download.downloadLink}
                      hash={download.sha}
                      note={download.note}
                      title={`${guiWalletDownloadInfo.type} for ${download.for}`}
                    />
                  </div>
                );
              })}
            </section>
          </div>
        )}
      </div>
    </section>
  );
};
