interface Props {
  title: string;
  fileUrl: string;
  handleBlur: (event: any) => void;
}

export const NavItemDownload: React.FC<Props> = ({
  title,
  handleBlur,
  fileUrl,
}) => {
  return (
    <li className="nav__links__item">
      <a
        download
        onMouseLeave={handleBlur}
        className="nav__link"
        href={fileUrl}
      >
        {title}
      </a>
    </li>
  );
};
