import { images } from '../assets';
import { SocialBar } from './SocialBar';

export const Footer: React.SFC = () => (
  <section className="footer u-bg--dark">
    <div className="wrapper">
      <div className="footer__grid">
        <div className="footer__grid__branding">
          <img
            src={images.complexLogo}
            alt="Tolar logo"
            className="logo logo--footer"
          />
        </div>
        <div className="footer__grid__social">
          <SocialBar isHorizontal={true} />
          <p className="u-type--micro u-type--negative u-type--wight--thin u-opacity--30">
            Connect with us
          </p>
        </div>
        <div className="footer__grid__copyright">
          <p className="u-type--micro u-type--negative u-type--wight--thin u-opacity--30">
            Copyright &copy; 2018 Tolar.io All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </section>
);
