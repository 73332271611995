interface Props {
  link: string;
  hash?: string;
  note?: string;
  text?: string;
  title: string;
  logo: any;
}

export const DownloadButton: React.SFC<Props> = ({
  link,
  hash,
  note,
  text,
  title,
  logo,
}) => (
  <div className="download__os__wrapper align-center justify-between u-spc-bottom--med u-spc-top--med">
    <div>
      <img src={logo} alt="Apple" className="download__os__logo" />
      <span className="download__os__title">{title}</span>
      {hash && (
        <p className="u-type--nano">
          <span className="u-opacity--50">
            <strong>SHA256</strong>:
          </span>{' '}
          <span className="u-opacity--30 u-type--wrap">{hash}</span>
        </p>
      )}
      {note && (
        <p className="download__os__note u-type--nano">
          <span className="u-opacity--50">NOTE:</span>{' '}
          <span className="u-opacity--30 u-type--wrap">{note}</span>
        </p>
      )}
    </div>
    {link ? (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn--sml btn--primary download__btn"
      >
        {text || 'Download'}
      </a>
    ) : (
      <p className="btn btn--sml btn--primary download__btn">Coming soon</p>
    )}
  </div>
);
