interface Props {
  children: any;
  additionalClasses?: string;
  onClose: () => void;
}

export const Modal: React.SFC<Props> = ({
  children,
  additionalClasses,
  onClose,
}) => {
  let modalClassNames = 'modal';
  if (additionalClasses) {
    modalClassNames += ` ${additionalClasses}`;
  }

  return (
    <div className={modalClassNames}>
      <div className="modal__wrapper">
        <div className="modal__close">
          <button onClick={onClose} className="btn btn--primary btn--med">
            Close
          </button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};
