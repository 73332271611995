import CoinGecko from 'coingecko-api';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

/** Official types are missing a lot of stuff. */
const client: any = new CoinGecko();

interface PriceHistory {
  timestamp: number;
  price: number;
}

export const ValueToday: React.FC = () => {
  const [priceHistory, setPriceHistory] = useState<PriceHistory[]>();

  useEffect(() => {
    client.coins
      .fetchMarketChart('tolar', { days: 1 })
      .then((response: any) => {
        const history: PriceHistory[] = response.data.prices.map(
          (item: any) => {
            return { timestamp: item[0], price: item[1] };
          },
        );

        setPriceHistory(history);
      });
  }, []);

  const populateData = () => (canvas: any) => {
    if (!priceHistory) {
      return { labels: [], datasets: [] };
    }

    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);

    gradient.addColorStop(0, 'rgba(22,51,69,1)');
    gradient.addColorStop(1, 'rgba(26,29,46,0)');

    return {
      labels: priceHistory.map((item) => item.timestamp),
      datasets: [
        {
          borderWidth: 1,
          borderColor: '#01B3D2',
          data: priceHistory.map((item) => item.price),
          lineTension: 0.3,
        },
      ],
    };
  };

  function populateOptions() {
    return {
      aspectRatio: 4,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        xAxis: {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
        yAxes: {
          ticks: {
            display: false,
            maxTicksLimit: 3,
          },
          gridLines: {
            color: 'rgba(255,255,255,0.25)',
            lineWidth: 1,
            drawBorder: false,
          },
        },
      },
      layout: {
        padding: 0,
      },
      tooltips: {
        displayColors: false,
        titleFontSize: 0,
        titleSpacing: 0,
        cornerRadius: 0,
        caretSize: 0,
        caretPadding: 0,
        titleMarginBottom: 0,
        xPadding: 6,
        titleFontFamily: `'Ringside Wide A', 'Ringside Wide B', -apple-system,
          BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
          'Helvetica Neue', Helvetica, Arial, sans-serif`,
        backgroundColor: 'rgba(43, 47, 52, 0.8)',
        yPadding: 6,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      elements: {
        point: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0)',
          hitRadius: 30,
          radius: 0,
          borderWidth: 10,
        },
      },
      animation: {
        duration: 0,
      },
      responsiveAnimationDuration: 0,
    };
  }

  return (
    <>
      <Line options={populateOptions()} data={populateData()} />
    </>
  );
};
