interface Props {
  nextSectionCallback?: () => void;
}

export const ToTopBar: React.SFC<Props> = ({ nextSectionCallback }) => (
  <div className="to-top__wrapper">
    <div className="to-top">
      {/*<a className="to-top__item" href="https://t.me/tolarofficial">
            <svg
                className="to-top__icon to-top__icon--top"
                x="0px"
                y="0px"
                width="19px"
                height="9px"
                viewBox="0 0 19 9"
                >
                    <g transform="translate(10, -6)" fill="none">
                        <polyline transform="translate(0, 6.349242) rotate(45) translate(-29.349242, -88.349242) " points="35.3492424 82.3492424 35.3492424 94.3492424 23.3492424 94.3492424" />
                    </g>
                </svg>
                <span className="to-top__item__label u-type--upper">To top</span>
            </a>*/}
      <button onClick={nextSectionCallback} className="to-top__item">
        <svg
          className="to-top__icon"
          x="0px"
          y="0px"
          width="19px"
          height="9px"
          viewBox="0 0 19 9"
        >
          <g transform="translate(10, -6)" fill="none">
            <polyline
              transform="translate(0, 6.349242) rotate(45) translate(-29.349242, -88.349242) "
              points="35.3492424 82.3492424 35.3492424 94.3492424 23.3492424 94.3492424"
            />
          </g>
        </svg>
        <div className="to-top__item__label u-type--upper">To Content</div>
      </button>
    </div>
  </div>
);
