import { Member } from '../models';
import ShowMore from 'react-show-more';
import React from 'react';

interface Props {
  member: Member;
  // isNegative?: boolean;
}

export class Person extends React.Component<Props> {
  render() {
    const { member } = this.props;

    return (
      <div className="person">
        <div className="person__photo">
          <img src={member.imageUrl.default} alt={member.name} />
        </div>
        <div className="person__content">
          <p className="u-type--upper u-type--eta u-type--spacing--extra-wide">
            {member.position}
          </p>
          <h2 className="u-type--epsilon">{member.name}</h2>
          <div className="person__description u-type--eta u-opacity--60">
            <ShowMore anchorClass="person__more u-type--upper u-type--micro u-display--b u-type--spacing--wide">
              {member.description}
            </ShowMore>
          </div>
          {member.group === 'Founding board' && (
            <a className="person__linkedin" href={member.linkedin}>
              <svg
                x="0px"
                y="0px"
                width="14px"
                height="14px"
                viewBox="0 0 14 14"
              >
                <g>
                  <rect x="0.2" y="4.7" width="2.9" height="9.3" />
                  <path d="M1.7,0C0.7,0,0,0.8,0,1.7c0,1,0.8,1.7,1.7,1.7s1.7-0.7,1.7-1.7C3.4,0.7,2.6,0,1.7,0z" />
                  <path
                    d="M10.5,4.4c-1.4,0-2.4,0.8-2.7,1.5H7.7V4.7H5.1V14h2.9V9.4C7.9,8.2,8.1,7,9.6,7s1.5,1.3,1.5,2.4v4.5H14V8.8
                      C14,6.4,13.5,4.4,10.5,4.4z"
                  />
                </g>
              </svg>
            </a>
          )}
        </div>
      </div>
    );
  }
}
