import { Member } from 'main/models';

export const FoundingBoard: Member[] = [
  {
    name: 'Josip Maričević',
    position: 'Founder / CTO',
    group: 'Founding board',
    description:
      'Researching blockchain and related consensus algorithms, atomic cross-chain swaps and of- chain settlement protocols for the past 2 years. Also worked on development of a few signifcant blockchain products. Interested in general algorithm research and development. Worked for Shazam on application that implements Shazam song recognition technology to identify songs in your iPod library and play them with synchronized lyrics and visualizations. Also an owner of software development agency Moon Code which is offering services of developing custom Blockchain based projects, alternative cryptocurrencies and Ethereum smart contracts.',
    imageUrl: require('main/assets/images/team/josip-maricevic.jpg'),
    linkedin: 'https://hr.linkedin.com/in/josip-maricevic-a0b58959',
  },
  {
    name: 'Drazen Kapusta',
    position: 'Founder/Principal',
    group: 'Founding board',
    description:
      'Drazen Kapusta is the Founder and Principal of TOLAR.io. He’s an entrepreneur with 30 years of business experience. Dražen also serves as Principal of COTRUGLI Business School, the leading business school in SE Europe where he has successfully helped develop the world’s first Blockchain MBA program and Certified Blockchain Developers Program. Drazen is the President of Blockchain Adria, the most prominent blockchain conference and association in SE Europe. He serves as the President of the COTRUGLI Fund which is focused on organizing and leading numerous humanitarian projects. ',
    imageUrl: require('main/assets/images/team/drazen-kapusta.jpg'),
    linkedin: 'https://hr.linkedin.com/in/cotrugli',
  },
  {
    name: 'Zoran Dordevic',
    position: 'Board Member',
    group: 'Founding board',
    description:
      'He has more than 15 years of experience in education and consulting as well as passion for blockchain technology and its educational and business implementation. Zoran is one of the pioneers in introducing blockchain technology into regular MBA curriculums that set the foundations of the world’s first Blockchain MBA program at COTRUGLI Business School. Besides his involvement in the educational business, he is very much involved in fostering entrepreneurial culture in the CEE region as consultant for large corporations, startups and ICO&#39;s. Zoran received his Master’s degree from University of Zagreb, Faculty of Economics and Business in Zagreb, Croatia and his MBA from COTRUGLI Business School in Zagreb, Croatia.',
    imageUrl: require('main/assets/images/team/zoran-dordevic.jpg'),
    linkedin: 'https://hr.linkedin.com/in/zorandordevic',
  },
  {
    name: 'Terence Tse',
    position: 'Board Member',
    group: 'Founding board',
    description:
      'Terence is the co-founder and CEO of Nexus FrontierTech, an artificial intelligence studio with years of experience in building and deploying AI for businesses. With more than 20 years of experience in financial corporate consulting, he continues to work closely with organizations like the EU and UN as well as commenting regularly on the latest current affairs, market and blockchain developments in the Financial Times, The Guardian, The Economist, CNBC, the World Economic Forum and the Harvard Business Review blogs. He obtained his PhD from the Cambridge Judge Business School, University of Cambridge, UK.',
    imageUrl: require('main/assets/images/team/terence-tse.jpg'),
    linkedin: 'https://uk.linkedin.com/in/terencetse',
  },
  {
    name: 'Tadej Slapnik ',
    position: 'Board Member',
    group: 'Founding board',
    description:
      'Director at Tolar HashNET, Co-Chair of High Level Group Blockchain for SDG at United Nations Economic Commission for Europe (UNECE) and Centre for Trade Facilitation and Electronic Business (CEFACT), Chairman at Eunex - Euro Asia Exchange, Principal at European Blockchain Hub and Council member at Circular Change platform. He is a former State Secretary of the Office of the Prime Minister of the Republic of Slovenia, initiator and signatory of Feel the future declaration: Initiative Blockchain Europe, actively involved in promotion, development and smart regulation of circular economy, blockchain and distributed ledger technology for achieving Sustainable Development Goals at national, European and global level.',
    imageUrl: require('main/assets/images/team/tadej-slapnik.jpg'),
    linkedin: 'https://www.linkedin.com/in/tadej-slapnik-59683747/',
  },
  {
    name: 'Sasa Pekec',
    position: 'Board Member',
    group: 'Founding board',
    description:
      'Sasa Pekec is an Associate Professor in the Decision Sciences Area at the Fuqua School of Business at Duke University. With more than 25 years of experience, he is currently teaching the core statistics course in the daytime MBA and the Cross Continent MBA programs. His work on combinatorial auctions had been widely cited and had influenced design of a new generation of now standard procurement auction procedures in a variety of industries. His consulting experience includes banking, internet, pharmaceutical, retail, and telecommunications industries. Sasa serves on the Supervisory Board of Atlantic Group, one of the leading FMCG companies in SE Europe. He was a member of the Council of Economic Advisors to the President of Croatia. He holds a Ph.D. in Applied Mathematics from Rutgers University. ',
    imageUrl: require('main/assets/images/team/sasa-pekec.jpg'),
  },
  {
    name: 'Alan Kuresevic',
    position: 'Board Member',
    group: 'Founding board',
    description:
      "Alan is the Vice President, Engineering at SES TechCom, world-leading satellite operator and the first to deliver a differentiated and scalable GEO-MEO offering worldwide, with more than 50 satellites in Geostationary Earth Orbit (GEO) and 12 in Medium Earth Orbit (MEO). The company provides satellite communications services to broadcasters, content and internet service providers, mobile and fixed network operators, governments and institutions. With more than 20 years of experience he successfully led a number of projects and technology development that provided foundation for the company's new service developments.",
    imageUrl: require('main/assets/images/team/alan-kuresevic.jpg'),
    linkedin: 'https://www.linkedin.com/in/alankuresevic/',
  },
  {
    name: 'Jeffrey Treichel',
    position: 'Board Member',
    group: 'Founding board',
    description:
      "Jeffrey is an entrepreneurial leader with 20+ years of experience and expertise in developing and rapidly operationalizing business models and brand strategies. He currently serves as Interim CEO of PaperKarma, one of Apple's top disruptive apps. He also founded and led Kolektiva, the first and largest daily deal site in the CEE region, raising $10+ million in VC and eventually building a $35 million business. Later on he worked with Shoutem, a leading mobile app development platform for SMEs, where he successfully doubled revenues in his first year by securing new Enterprise deals with Fortune 500 media and communication companies. He earned his MBA degree from Harvard Business School and his BA from Tufts University.",
    imageUrl: require('main/assets/images/team/jeffrey-treichel.jpg'),
    linkedin: 'https://www.linkedin.com/in/jeffreytreichel',
  },
  {
    name: 'Marko Kucic',
    position: 'Board Member',
    group: 'Founding board',
    description:
      'A skilled person with years of managerial experience, focused on business development, sales, marketing and operations. Successful business track record domestically and regionally, working as managing partner at the leading business school in SE Europe specialized in providing MBA (postgraduate) and tailor made educational programs targeting managers from various organizations in SE Europe.',
    imageUrl: require('main/assets/images/team/marko-kucic.jpg'),
    linkedin: 'https://www.linkedin.com/in/marko-kučić-3538354/',
  },
];
export const HashnetTech: Member[] = [
  {
    name: 'Mario Vojvoda',
    position: 'CIO',
    group: 'HashNET bizz',
    description:
      'Mario is an IT Leader with more than 15 years of experience in the telecom industry, innovation, R&D and international business development within the challenging environment. Keeping up-to-date with new and emerging technologies, he has proven expertise in increasing revenues and boosting profitability of various projects and companies he has worked. He was a board member of Combis, a renowned informatics company in the CEE region with market-recognised ICT solutions. With his strong technical knowledge in IT and telco areas, he also served as IT Director of T-Mobile Croatia and was CTO of Iskon.',
    imageUrl: require('main/assets/images/team/mario-vojvoda.jpg'),
  },
  {
    name: 'Martin Zagar',
    position: 'Science',
    group: 'HashNET tech',
    description:
      'His occupational fields are multimedia computer architectures and telemedicine. He has participated in several domestic and international scientific projects and also participated in supervising of the Information system in primary healthcare in the Republic of Croatia and is co-author of the Concept of Integrated Central System Authentication and Authorization. Martin Žagar has been awarded with EMBA from Cotrugli Business School and PhD in Computer Science from the UNIZG.',
    imageUrl: require('main/assets/images/team/martin-zagar.jpg'),
  },
  {
    name: 'Kristijan Rebernisak',
    position: 'Solidity / Ethereum Virtual Machine expert',
    group: 'HashNET tech',
    description:
      'Kristijan is a computing and information technologies engineer, a lifelong developer with a strong passion for decentralization, explorer of decentralized business models and token-enabled governance structures. His extensive experience includes working as a leader of the software engineering team and as a software architect on challenging products in wearables, health, retail, the music industry and others. Currently also co-founder of RunningBeta, a company that builds unstoppable applications that run on open standards, leveraging the power of Ethereum.',
    imageUrl: require('main/assets/images/team/kristijan-rebernisak.jpg'),
  },
  {
    name: 'Vedran Novoselac',
    position: 'Core Developer',
    group: 'HashNET tech',
    description:
      'Experienced Linux backend developer and architect, whose skills cover topics such as: design and specification, Agile, code quality, implementation, testing, refactoring and maintenance, configuration, release management, code and package management, build automation, deployment, and documentation. He’s also been fulfilling DevOps role as demanded by the past projects. Wider adoption of blockchain expanded his interests towards cryptocurrency trading and technologies, but also made him an avid explorer in area of blockchain cryptosystems and their applications in the real world. In total, more than 14 years of experience as a software developer.',
    imageUrl: require('main/assets/images/team/vedran-novoselac.jpg'),
  },

  {
    name: 'Vinko Bradvica',
    position: 'Core Developer',
    group: 'HashNET tech',
    description:
      'Vinko is an avid trail runner and computing engineer with experience in system design, product design, project and technical management. Co-founder of RunningBeta, a company focused on building the infrastructure, protocols, and applications in a decentralized world. With multiple years in professional software development, he has worked as a leader of both product design and software development teams of products used by millions of users. As an active developer and advocate of blockchain technology, he provides consultancy and advice to selected ICOs.',
    imageUrl: require('main/assets/images/team/vinko-bradvica.jpg'),
  },
  {
    name: 'Igor Jerkovic',
    position: 'Core Developer',
    group: 'HashNET tech',
    description:
      'Igor is coming from algorithm competitions background, but currently mostly interested in how to use the ongoing crypto and distributed ledger advancements into something of a more broad use. Worked for Facebook as Software Engineer for several years.',
    imageUrl: require('main/assets/images/team/igor-jerkovic.jpg'),
  },
  {
    name: 'Sandi Fatic',
    position: 'Core Developer',
    group: 'HashNET tech',
    description:
      'Researching new distributed consensus algorithms, and implementing them. Worked as Software Engineer at Memgraph - high performance, in-memory, transactional graph database. Also has experience of working in Google, first as Technical Associate and later as Site Reliability Engineer.',
    imageUrl: require('main/assets/images/team/sandi-fatic.jpg'),
  },
  {
    name: 'Iva Brajer',
    position: '',
    group: 'HashNET tech',
    description:
      'Worked as backend software engineer for the past 8 years mainly with technology stack based on C/C++. Has experience in various domains like internet security, cryptography, RESTful services architecture, relational databases, networking protocols, data analysis, machine learning. As Master of Computer Science has strong interest in producing software in research & development cycle where scientific work comes equally important as development work. Interested in blockchain projects because of great challenges they can offer to someone who likes to dwell into problem solving from different perspectives.',
    imageUrl: require('main/assets/images/team/iva-brajer.jpg'),
  },
  {
    name: 'Mario Halambek',
    position: '',
    group: 'HashNET tech',
    description:
      'Mario has a degree in Computer Science. He is a backend developer with focus on optimization of development process. Always looking for true challenges to solve – If it’s easy it’s not worth the time.',
    imageUrl: require('main/assets/images/team/mario-halambek.jpg'),
  },
  {
    name: 'Marko Bacic',
    position: '',
    group: 'HashNET tech',
    description:
      'Marko graduated from the University of Zagreb, Faculty of Electrical Engineering and Computing with a Master in Computer Applications. Marko has experience in working on large, robust and complex applications. He is interested in distributed systems, network programming and high performance applications.',
    imageUrl: require('main/assets/images/team/marko-bacic.jpg'),
  },
  {
    name: 'Dario Pazin',
    position: 'Core developer',
    group: 'HashNET tech',
    description:
      'Dario worked previously as a Data Engineer at Instantor AB, Fintech company. During his previous jobs, he worked with Spark & Scala and various other technologies. He has a strong interest in algorithms, data analysis and blockchain projects.',
    linkedin: 'https://www.linkedin.com/in/dpazin/',
    imageUrl: require('main/assets/images/team/dario-pazin.jpg'),
  },
];
export const HashNetBizz: Member[] = [
  {
    name: 'Bojan Hadzisejdic',
    position: 'BDM',
    group: 'HashNET bizz',
    description:
      'Serving as Business Development Manager of TOLAR.io, Bojan is an experienced sales professional with strong business and technical skills gained through 15 years of experience in the IT industry, both corporate and SME level. He started his professional career as a BDM at Microsoft (2003-2015), eventually becoming a cloud transformation consultant as well as director of Nephos, a company dedicated to help businesses transform and grow, implementing digital transformation. This means developing a comprehensive concept of using modern technologies that is consistent with strategic business development goals. Bojan received his Master&#39;s degree from the Faculty of Electrical Engineering and Computing, University of Zagreb and his Executive MBA from COTRUGLI Business School.',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Aco Momcilovic',
    position: 'HRM',
    group: 'HashNET bizz',
    description:
      'With more than 10 years of experience in strategic organisation of Human Resources, including the responsibility for staff members as well as budget balance, Aco serves as Human Resources Manager of TOLAR.io, guaranteeing strategic HR objectives as well as functional and disciplinary guidance of the HR department. Among being a blockchain consultant, lecturer and ICO investor, Aco also serves as President of MBA Croatia, gathering all Croatian MBA Alumni from all domestic and foreign MBA Programs. He was also Chief HR Officer at Rimac Automobili, and has worked with numerous companies in matters of HR and consulting. Aco holds a Master’s degree in Psychology from the University of Zagreb and an Executive MBA from COTRUGLI Business School.',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Neda Zajko',
    position: 'CMO',
    group: 'HashNET bizz',
    description:
      'With more than 8 years of experience in marketing and communications Neda serves as CMO of TOLAR.io. With her vast knowledge of digital marketing, Neda is in charge of campaign management and marketing strategies while supervising a team of social media experts and following blockchain marketing trends. She received her Bc degree in Commerce and marketing from Concordia University and her MBA degree from COTRUGLI Business School',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Isidora Vazic',
    position: 'Community Manager',
    group: 'HashNET bizz',
    description:
      'Isidora is responsible for all social media communications at TOLAR.io. She has 4+ years of experience in sales and communication. Isidora holds a Bc degree in Communications from Megatrend University.',
    imageUrl: require('main/assets/images/team/isidora-vazic.jpg'),
  },
  {
    name: 'Gordana Spisic',
    position: 'Manager',
    group: 'HashNET bizz',
    description:
      'Gordana is in charge of communication with strategic partners and customers on a regular basis while also integrating customer and partner product needs as well as contract requirements with business operations and product roadmaps. With 10+ years of management experience, she is responsible for achieving strategic account targets and conducting research to identify and validate new markets and customer needs.',
    imageUrl: require('main/assets/images/team/gordana-spisic.jpg'),
  },
  {
    name: 'Ljiljana Babic',
    position: 'Manager',
    group: 'HashNET bizz',
    description:
      'Ljiljana is charge of communication with partners and customers on a regular basis and responding to specific queries. She is responsible for achieving strategic account targets and scope/design approaches to capture business development opportunities, integrate customer and partner product needs and contract requirements with business operations and product roadmaps. Ljiljana also has 10+ years of experience as a Business Development Director for Serbia, Bosnia and Herzegovina, Montenegro, Romania, Bulgaria and Macedonia at COTRUGLI Business School.',
    imageUrl: require('main/assets/images/team/ljiljana-babic.jpg'),
  },

  {
    name: 'Dorijan Palic',
    position: 'Design',
    group: 'HashNET bizz',
    description:
      'Dorijan has been working on TOLAR.io since its very beginning. He is in charge of the complete design section, from creating the design and layouts of websites to additional design implementations and materials.',
    imageUrl: require('main/assets/images/team/dorijan-palic.jpg'),
  },
];
export const Advisors: Member[] = [
  {
    name: 'Manuel Alonso Coto',
    position: 'PhD',
    group: 'Advisors',
    description:
      'Manuel Alonso Coto, PhD is visiting professor at COTRUGLI Business School. He has received PhD in Economics (Thesis about digital business in Spain), King Juan Carlos University, Top Management Program (AMP), and Chicago Graduate School of Business &IE Business School. He graduated Executive MBA at IE Business School and Master in Foreign Trade, EOI Business School. He completed the Postgrad in Business Administration (with a focus on Finance), UNED University, Postgrad in Psychology (with a focus on Advertising), and UNED University. During his career he has worked as a Director of Marketing, Executive Education, IE Business School, Director of Digital Marketing, Executive Education, IE Business School, Director of Customer Oriented Services, IE Business School, Director of Operations, ielearning.net, Director of IT/IS, US Department of Spain, Spain & Portugal and Director of Manufacturing, Agrapisa. He is an author of the Books: “Political Marketing”, “Direct Marketing 2.0”, “Blended Marketing. The Digital Marketing Plan as an integrator of on and offline actions”, “Conversational Capital” (Spanish version), “From Second Life to Metaverses Marketing: Business in 3D”, “I have 30,000 fans; so now what?”, “Social Media Marketing Plan”, “s-Internationalization”.',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Valerio Opacic',
    position: 'Access and Security Services',
    group: 'Advisors',
    description:
      'Valerio Opacic, as a member of the Tolar team, is in charge for the Security of the Hashnet protocol. He has more than 26 years of working experience mostly dealing with Global Identity, Access and Security Services Manager at McDonald&#39;s Corporation. He is experienced in building globally scoped enterprise systems, with prime focus to computing platforms and underlying systems such as compliance, directories, security, platforms and applications. Besides technical expertise and historical development, he is managing complex IT systems from business perspective is also a strong asset. Integral part of engagement in his profession was always linked to non-technical aspects of primarily technical function, such as procurement, service buying or selling, consulting and alike. His recent education and interest is focused toward general management, with emphasis to implementation and alignment of technology and business, crowned by completion of MBA at COTRUGLI Business School',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
];
export const MEMBERS: Member[] = [
  {
    name: 'Josip Maričević',
    position: 'Founder / CTO',
    group: 'Founding board',
    description:
      'Researching blockchain and related consensus algorithms, atomic cross-chain swaps and of- chain settlement protocols for the past 2 years. Also worked on development of a few signifcant blockchain products. Interested in general algorithm research and development. Worked for Shazam on application that implements Shazam song recognition technology to identify songs in your iPod library and play them with synchronized lyrics and visualizations. Also an owner of software development agency Moon Code which is offering services of developing custom Blockchain based projects, alternative cryptocurrencies and Ethereum smart contracts.',
    imageUrl: require('main/assets/images/team/josip-maricevic.jpg'),
    // linkedin: 'https://hr.linkedin.com/in/josip-maricevic-a0b58959'
  },
  {
    name: 'Drazen Kapusta',
    position: 'Founder/Principal',
    group: 'Founding board',
    description:
      'Drazen Kapusta is the Founder and Principal of TOLAR.io. He’s an entrepreneur with 30 years of business experience. Dražen also serves as Principal of COTRUGLI Business School, the leading business school in SE Europe where he has successfully helped develop the world’s first Blockchain MBA program and Certified Blockchain Developers Program. Drazen is the President of Blockchain Adria, the most prominent blockchain conference and association in SE Europe. He serves as the President of the COTRUGLI Fund which is focused on organizing and leading numerous humanitarian projects. ',
    imageUrl: require('main/assets/images/team/drazen-kapusta.jpg'),
    // linkedin: 'https://hr.linkedin.com/in/cotrugli'
  },
  {
    name: 'Zoran Dordevic',
    position: 'Board Member',
    group: 'Founding board',
    description:
      'He has more than 15 years of experience in education and consulting as well as passion for blockchain technology and its educational and business implementation. Zoran is one of the pioneers in introducing blockchain technology into regular MBA curriculums that set the foundations of the world’s first Blockchain MBA program at COTRUGLI Business School. Besides his involvement in the educational business, he is very much involved in fostering entrepreneurial culture in the CEE region as consultant for large corporations, startups and ICO&#39;s. Zoran received his Master’s degree from University of Zagreb, Faculty of Economics and Business in Zagreb, Croatia and his MBA from COTRUGLI Business School in Zagreb, Croatia.',
    imageUrl: require('main/assets/images/team/zoran-dordevic.jpg'),
    // linkedin: 'https://hr.linkedin.com/in/zorandordevic'
  },
  {
    name: 'Terence Tse',
    position: 'Board Member',
    group: 'Founding board',
    description:
      'Terence is the co-founder and CEO of Nexus FrontierTech, an artificial intelligence studio with years of experience in building and deploying AI for businesses. With more than 20 years of experience in financial corporate consulting, he continues to work closely with organizations like the EU and UN as well as commenting regularly on the latest current affairs, market and blockchain developments in the Financial Times, The Guardian, The Economist, CNBC, the World Economic Forum and the Harvard Business Review blogs. He obtained his PhD from the Cambridge Judge Business School, University of Cambridge, UK.',
    imageUrl: require('main/assets/images/team/terence-tse.jpg'),
    // linkedin: 'https://uk.linkedin.com/in/terencetse'
  },
  {
    name: 'Sasa Pekec',
    position: 'Board Member',
    group: 'Founding board',
    description:
      'Sasa Pekec is an Associate Professor in the Decision Sciences Area at the Fuqua School of Business at Duke University. With more than 25 years of experience, he is currently teaching the core statistics course in the daytime MBA and the Cross Continent MBA programs. His work on combinatorial auctions had been widely cited and had influenced design of a new generation of now standard procurement auction procedures in a variety of industries. His consulting experience includes banking, internet, pharmaceutical, retail, and telecommunications industries. Sasa serves on the Supervisory Board of Atlantic Group, one of the leading FMCG companies in SE Europe. He was a member of the Council of Economic Advisors to the President of Croatia. He holds a Ph.D. in Applied Mathematics from Rutgers University. ',
    imageUrl: require('main/assets/images/team/sasa-pekec.jpg'),
  },
  {
    name: 'Alan Kuresevic',
    position: 'Board Member',
    group: 'Founding board',
    description:
      "Alan is the Vice President, Engineering at SES TechCom, world-leading satellite operator and the first to deliver a differentiated and scalable GEO-MEO offering worldwide, with more than 50 satellites in Geostationary Earth Orbit (GEO) and 12 in Medium Earth Orbit (MEO). The company provides satellite communications services to broadcasters, content and internet service providers, mobile and fixed network operators, governments and institutions. With more than 20 years of experience he successfully led a number of projects and technology development that provided foundation for the company's new service developments.",
    imageUrl: require('main/assets/images/team/alan-kuresevic.jpg'),
    // linkedin: 'https://www.linkedin.com/in/alankuresevic/'
  },
  {
    name: 'Jeffrey Treichel',
    position: 'Board Member',
    group: 'Founding board',
    description:
      "Jeffrey is an entrepreneurial leader with 20+ years of experience and expertise in developing and rapidly operationalizing business models and brand strategies. He currently serves as Interim CEO of PaperKarma, one of Apple's top disruptive apps. He also founded and led Kolektiva, the first and largest daily deal site in the CEE region, raising $10+ million in VC and eventually building a $35 million business. Later on he worked with Shoutem, a leading mobile app development platform for SMEs, where he successfully doubled revenues in his first year by securing new Enterprise deals with Fortune 500 media and communication companies. He earned his MBA degree from Harvard Business School and his BA from Tufts University.",
    imageUrl: require('main/assets/images/team/jeffrey-treichel.jpg'),
    // linkedin: 'https://www.linkedin.com/in/jeffreytreichel'
  },
  {
    name: 'Marko Kucic',
    position: 'Board Member',
    group: 'Founding board',
    description:
      "CV is an abbreviation for Curriculum Vitae. If a job advertisement asks for a CV, that's a hint that the employer expects a great deal of life experience and accomplishments, including education, original research. ",
    imageUrl: require('main/assets/images/team/marko-kucic.jpg'),
    // linkedin: 'https://www.linkedin.com/in/marko-kui-3538354/'
  },

  {
    name: 'Martin Zagar',
    position: 'Science',
    group: 'HashNET tech',
    description:
      'His occupational fields are multimedia computer architectures and telemedicine. He has participated in several domestic and international scientific projects and also participated in supervising of the Information system in primary healthcare in the Republic of Croatia and is co-author of the Concept of Integrated Central System Authentication and Authorization. Martin Žagar has been awarded with EMBA from Cotrugli Business School and PhD in Computer Science from the UNIZG.',
    imageUrl: require('main/assets/images/team/martin-zagar.jpg'),
  },
  {
    name: 'Igor Jerkovic',
    position: 'Core Developer',
    group: 'HashNET tech',
    description:
      'Igor is coming from algorithm competitions background, but currently mostly interested in how to use the ongoing crypto and distributed ledger advancements into something of a more broad use. Worked for Facebook as Software Engineer for several years.',
    imageUrl: require('main/assets/images/team/igor-jerkovic.jpg'),
  },
  {
    name: 'Sandi Fatic',
    position: 'Core Developer',
    group: 'HashNET tech',
    description:
      'Researching new distributed consensus algorithms, and implementing them. Worked as Software Engineer at Memgraph - high performance, in-memory, transactional graph database. Also has experience of working in Google, first as Technical Associate and later as Site Reliability Engineer.',
    imageUrl: require('main/assets/images/team/sandi-fatic.jpg'),
  },
  {
    name: 'Vedran Novoselac',
    position: 'Core Developer',
    group: 'HashNET tech',
    description:
      'Experienced Linux backend developer and architect, whose skills cover topics such as: design and specification, Agile, code quality, implementation, testing, refactoring and maintenance, configuration, release management, code and package management, build automation, deployment, and documentation. He’s also been fulfilling DevOps role as demanded by the past projects. Wider adoption of blockchain expanded his interests towards cryptocurrency trading and technologies, but also made him an avid explorer in area of blockchain cryptosystems and their applications in the real world. In total, more than 14 years of experience as a software developer.',
    imageUrl: require('main/assets/images/team/vedran-novoselac.jpg'),
  },
  {
    name: 'Srdjan Maksimovic',
    position: 'Algorithm research',
    group: 'HashNET tech',
    description:
      'Master’s degree in Computer Science and master’s degreee in Mathematics, participant in International Mathematical Olympiads. Avid developer and researcher of different types of algorithms, from network flow to consensus. Does not believe in social media.',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Kristijan Rebernisak',
    position: 'Solidity / Ethereum Virtual Machine expert',
    group: 'HashNET tech',
    description:
      'Kristijan is a computing and information technologies engineer, a lifelong developer with a strong passion for decentralization, explorer of decentralized business models and token-enabled governance structures. His extensive experience includes working as a leader of the software engineering team and as a software architect on challenging products in wearables, health, retail, the music industry and others. Currently also co-founder of RunningBeta, a company that builds unstoppable applications that run on open standards, leveraging the power of Ethereum.',
    imageUrl: require('main/assets/images/team/kristijan-rebernisak.jpg'),
  },
  {
    name: 'Vinko Bradvica',
    position: 'Core Developer',
    group: 'HashNET tech',
    description:
      'Vinko is an avid trail runner and computing engineer with experience in system design, product design, project and technical management. Co-founder of RunningBeta, a company focused on building the infrastructure, protocols, and applications in a decentralized world. With multiple years in professional software development, he has worked as a leader of both product design and software development teams of products used by millions of users. As an active developer and advocate of blockchain technology, he provides consultancy and advice to selected ICOs.',
    imageUrl: require('main/assets/images/team/vinko-bradvica.jpg'),
  },
  {
    name: 'Mario Vojvoda',
    position: 'CIO',
    group: 'HashNET bizz',
    description:
      'Mario is an IT Leader with more than 15 years of experience in the telecom industry, innovation, R&D and international business development within the challenging environment. Keeping up-to-date with new and emerging technologies, he has proven expertise in increasing revenues and boosting profitability of various projects and companies he has worked. He was a board member of Combis, a renowned informatics company in the CEE region with market-recognised ICT solutions. With his strong technical knowledge in IT and telco areas, he also served as IT Director of T-Mobile Croatia and was CTO of Iskon.',
    imageUrl: require('main/assets/images/team/mario-vojvoda.jpg'),
  },
  {
    name: 'Neda Zajko',
    position: 'CMO',
    group: 'HashNET bizz',
    description:
      'With more than 8 years of experience in marketing and communications Neda serves as CMO of TOLAR.io. With her vast knowledge of digital marketing, Neda is in charge of campaign management and marketing strategies while supervising a team of social media experts and following blockchain marketing trends. She received her Bc degree in Commerce and marketing from Concordia University and her MBA degree from COTRUGLI Business School',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Ljiljana Babic',
    position: 'Manager',
    group: 'HashNET bizz',
    description:
      'Ljiljana is charge of communication with partners and customers on a regular basis and responding to specific queries. She is responsible for achieving strategic account targets and scope/design approaches to capture business development opportunities, integrate customer and partner product needs and contract requirements with business operations and product roadmaps. Ljiljana also has 10+ years of experience as a Business Development Director for Serbia, Bosnia and Herzegovina, Montenegro, Romania, Bulgaria and Macedonia at COTRUGLI Business School.',
    imageUrl: require('main/assets/images/team/ljiljana-babic.jpg'),
  },
  {
    name: 'Bojan Hadzisejdic',
    position: 'BDM',
    group: 'HashNET bizz',
    description:
      'Serving as Business Development Manager of TOLAR.io, Bojan is an experienced sales professional with strong business and technical skills gained through 15 years of experience in the IT industry, both corporate and SME level. He started his professional career as a BDM at Microsoft (2003-2015), eventually becoming a cloud transformation consultant as well as director of Nephos, a company dedicated to help businesses transform and grow, implementing digital transformation. This means developing a comprehensive concept of using modern technologies that is consistent with strategic business development goals. Bojan received his Master&#39;s degree from the Faculty of Electrical Engineering and Computing, University of Zagreb and his Executive MBA from COTRUGLI Business School.',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Aco Momcilovic',
    position: 'HRM',
    group: 'HashNET bizz',
    description:
      'With more than 10 years of experience in strategic organisation of Human Resources, including the responsibility for staff members as well as budget balance, Aco serves as Human Resources Manager of TOLAR.io, guaranteeing strategic HR objectives as well as functional and disciplinary guidance of the HR department. Among being a blockchain consultant, lecturer and ICO investor, Aco also serves as President of MBA Croatia, gathering all Croatian MBA Alumni from all domestic and foreign MBA Programs. He was also Chief HR Officer at Rimac Automobili, and has worked with numerous companies in matters of HR and consulting. Aco holds a Master’s degree in Psychology from the University of Zagreb and an Executive MBA from COTRUGLI Business School.',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Gordana Spisic',
    position: 'Manager',
    group: 'HashNET bizz',
    description:
      'Gordana is in charge of communication with strategic partners and customers on a regular basis while also integrating customer and partner product needs as well as contract requirements with business operations and product roadmaps. With 10+ years of management experience, she is responsible for achieving strategic account targets and conducting research to identify and validate new markets and customer needs.',
    imageUrl: require('main/assets/images/team/gordana-spisic.jpg'),
  },
  {
    name: 'Dorijan Palic',
    position: 'Design',
    group: 'HashNET bizz',
    description:
      'Dorijan has been working on TOLAR.io since its very beginning. He is in charge of the complete design section, from creating the design and layouts of websites to additional design implementations and materials.',
    imageUrl: require('main/assets/images/team/dorijan-palic.jpg'),
  },
  {
    name: 'Isidora Vazic',
    position: 'Community Manager',
    group: 'HashNET bizz',
    description:
      'Isidora is responsible for all social media communications at TOLAR.io. She has 4+ years of experience in sales and communication. Isidora holds a Bc degree in Communications from Megatrend University.',
    imageUrl: require('main/assets/images/team/isidora-vazic.jpg'),
  },
  {
    name: 'Manuel Alonso Coto',
    position: 'PhD',
    group: 'Advisors',
    description:
      'Manuel Alonso Coto, PhD is visiting professor at COTRUGLI Business School. He has received PhD in Economics (Thesis about digital business in Spain), King Juan Carlos University, Top Management Program (AMP), and Chicago Graduate School of Business &IE Business School. He graduated Executive MBA at IE Business School and Master in Foreign Trade, EOI Business School. He completed the Postgrad in Business Administration (with a focus on Finance), UNED University, Postgrad in Psychology (with a focus on Advertising), and UNED University. During his career he has worked as a Director of Marketing, Executive Education, IE Business School, Director of Digital Marketing, Executive Education, IE Business School, Director of Customer Oriented Services, IE Business School, Director of Operations, ielearning.net, Director of IT/IS, US Department of Spain, Spain & Portugal and Director of Manufacturing, Agrapisa. He is an author of the Books: “Political Marketing”, “Direct Marketing 2.0”, “Blended Marketing. The Digital Marketing Plan as an integrator of on and offline actions”, “Conversational Capital” (Spanish version), “From Second Life to Metaverses Marketing: Business in 3D”, “I have 30,000 fans; so now what?”, “Social Media Marketing Plan”, “s-Internationalization”.',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
  {
    name: 'Valerio Opacic',
    position: 'Access and Security Services',
    group: 'Advisors',
    description:
      'Valerio Opacic, as a member of the Tolar team, is in charge for the Security of the Hashnet protocol. He has more than 26 years of working experience mostly dealing with Global Identity, Access and Security Services Manager at McDonald&#39;s Corporation. He is experienced in building globally scoped enterprise systems, with prime focus to computing platforms and underlying systems such as compliance, directories, security, platforms and applications. Besides technical expertise and historical development, he is managing complex IT systems from business perspective is also a strong asset. Integral part of engagement in his profession was always linked to non-technical aspects of primarily technical function, such as procurement, service buying or selling, consulting and alike. His recent education and interest is focused toward general management, with emphasis to implementation and alignment of technology and business, crowned by completion of MBA at COTRUGLI Business School',
    imageUrl: require('main/assets/images/team/member-generic.png'),
  },
];
