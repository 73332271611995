import anime from 'animejs';
import { Converter, DownloadButton } from 'main/components';
import { downloadInfo } from 'main/consts';
import React from 'react';

import ChromeLogo from 'main/assets/images/logos/logo-chrome.svg';
import MacLogo from 'main/assets/images/logos/logo-apple.svg';
import LinuxLogo from 'main/assets/images/logos/logo-linux.svg';
import WindowsLogo from 'main/assets/images/logos/logo-windows.svg';
import GithubLogo from 'main/assets/images/logos/logo-github.svg';

const logos = {
  Chrome: ChromeLogo,
  Mac: MacLogo,
  Linux: LinuxLogo,
  Windows: WindowsLogo,
};

export class Download extends React.Component {
  componentDidMount() {
    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.contentpage__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__subtitle',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.download',
        opacity: [0, 1],
        translateY: [40, 0],
        duration: 500,
        easing: 'easeOutQuint',
      });
  }

  render() {
    return (
      <section className="contentpage section--dark">
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .u-bg--video { opacity: 0.2 }
          .u-bg--deco { display: none }
          .u-bg--space { display: none }
        `,
          }}
        />
        <header className="contentpage__header">
          <div className="wrapper">
            <div className="download__header wrapper--medium">
              <div className="contentpage__title">
                <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                  Tools
                </h1>
              </div>
              <p className="contentpage__subtitle u-type--zeta u-type--secondary u-type--weight--ultra u-type--typography--wide u-opacity--70">
                Tolar HashNET mainnet clients are now available to general
                public. You can download both command line node binaries or GUI
                wallets.
              </p>
            </div>
            <main className="download">
              {downloadInfo?.map((section, index) => (
                <div className="download__wrapper" key={index}>
                  <section className="download__testnet">
                    <p className="u-type--gamma u-spc-bottom--med u-type--center">
                      {section.title}
                    </p>
                    {section?.downloads?.map((download, indexDown) => {
                      const thisLogo = logos[download.for];

                      return (
                        <div className="download__os" key={indexDown}>
                          <DownloadButton
                            logo={thisLogo}
                            link={download.downloadLink}
                            hash={download.sha}
                            note={download.note}
                            title={`${section.type} for ${download.for}`}
                          />
                        </div>
                      );
                    })}
                  </section>
                </div>
              ))}
              <div className="download__wrapper">
                <section className="download__testnet">
                  <p className="u-type--gamma u-spc-bottom--med u-type--center">
                    Github links
                  </p>
                  <div className="download__os">
                    <DownloadButton
                      logo={GithubLogo}
                      link="https://github.com/Tolar-HashNET/web3js"
                      hash="https://github.com/Tolar-HashNET/web3js"
                      title="Web3js"
                      text="Go to link"
                    />
                  </div>
                  <div className="download__os">
                    <DownloadButton
                      logo={GithubLogo}
                      link="https://github.com/Tolar-HashNET/web3j"
                      hash="https://github.com/Tolar-HashNET/web3j"
                      title="Web3j"
                      text="Go to link"
                    />
                  </div>
                </section>
              </div>

              <Converter />
            </main>
          </div>
        </header>
      </section>
    );
  }
}
