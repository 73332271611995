import * as views from '../views';

export interface AppRoute {
  title: string;
  path?: string;
  component: any;
  exact?: boolean;
  key?: number;
}

export const routes: AppRoute[] = [
  {
    title: 'Homepage',
    path: '/',
    component: views.Homepage,
    exact: true,
  },
  {
    title: 'HashNET',
    path: '/hashnet',
    component: views.Hashnet,
    exact: true,
  },
  {
    title: 'Token Sale',
    path: '/tokensale',
    component: views.TokenSale,
    exact: true,
  },
  {
    title: 'Team',
    path: '/team',
    component: views.Team,
    exact: true,
  },
  {
    title: 'Download',
    path: '/download',
    component: views.Download,
    exact: true,
  },
  {
    title: 'Testnet Faucet',
    path: '/testnet-faucet',
    component: views.Testnet,
    exact: true,
  },
  {
    title: 'Enterprise',
    path: '/enterprise',
    component: views.Enterprise,
    exact: true,
  },
  {
    title: 'Blog',
    path: '/blog',
    exact: true,
    component: views.Blog,
  },
  {
    title: 'Blog post',
    path: '/blog/:id',
    component: views.BlogPost,
  },
  {
    title: 'Taquin',
    path: '/taquin-plugin',
    exact: true,
    component: views.Taquin,
  },
  {
    /**
     * Error page doesn't need to have a route prop, as it is a fallback route
     * Key is set to 999 to ensure this route is rendered last
     */
    title: 'Error 404',
    component: views.Error404,
    key: 999,
  },
];
