import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';

interface State {
  email: string;
  errored: boolean;
  errorMessage?: string;
}
export class Newsletter extends React.Component<
  Record<string, unknown>,
  State
> {
  state: State = {
    email: '',
    errored: false,
    errorMessage: undefined,
  };

  isEmailValid = (email: string) => {
    const reg =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return reg.test(String(email).toLowerCase());
  };

  handleEmailChange = (event: any) => {
    const { value } = event.currentTarget || event.srcElement;
    this.setState({ email: value });
  };

  resetState = () => {
    this.setState({
      email: '',
      errored: false,
      errorMessage: undefined,
    });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const { email } = this.state;

    if (!email) {
      this.setState({
        errored: true,
        errorMessage: 'please enter a email address ',
      });
      event.preventDefault();
      return;
    }

    if (!this.isEmailValid(email!)) {
      this.setState({
        errored: true,
        errorMessage: 'please enter a valid email address ',
      });
      event.preventDefault();
      return;
    }

    setTimeout(() => this.resetState(), 100);
  };

  render() {
    const { email, errored, errorMessage } = this.state;
    return (
      <section className="newsletter">
        <ScrollableAnchor id={'newsletter'}>
          <div className="wrapper">
            <div className="newsletter__content">
              <h1 className="newsletter__title">
                <span className="u-type--epsilon u-type--upper u-type--spacing--extra-wide u-display--b u-type--typography--compressed u-type--secondary u-opacity--60">
                  Join us on our journey
                </span>
                <span className="u-type--gamma u-type--weight--ultra u-type--negative u-type--upper u-display--b">
                  beyond Blockchain
                </span>
              </h1>
              <p className="newsletter__claim u-type--eta u-type--secondary u-opacity--60 u-type--typography--wide">
                Sign up for project updates and insights in the world of Tolar
                and HashNET.
              </p>
              <form
                onSubmit={this.handleSubmit}
                action="https://tolar.us3.list-manage.com/subscribe/post?u=8a4e4d0136971fea914d97b58&amp;id=b98b3ef1ef"
                method="post"
                target="_blank"
                className="newsletter__form"
                noValidate
              >
                <input
                  type="email"
                  placeholder="Your e-mail.."
                  className="input input--med"
                  value={email}
                  name="EMAIL"
                  onChange={this.handleEmailChange}
                />
                <button type="submit" className="btn btn--primary btn--med">
                  Sign up
                </button>
              </form>
              {errored && errorMessage && <p>{errorMessage}</p>}
            </div>
          </div>
        </ScrollableAnchor>
      </section>
    );
  }
}
