import Helmet from 'react-helmet';
import { SEOModel } from '../models/Seo';

const SEO = ({ SEOTitle, SEODescription, SEOUrl }: SEOModel) => (
  <Helmet>
    <title>{SEOTitle}</title>
    <meta charSet="utf-8" />
    <link rel="canonical" href={SEOUrl} />
    <meta name="description" content={SEODescription} />
  </Helmet>
);

export default SEO;
