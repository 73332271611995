// General
import complexLogo from './logos/logo-tolar-complex.svg';
import simpleLogo from './logos/logo-tolar-simple.svg';
import patternBg from './background/pattern.jpg';
import dotsCircle from './background/dots-circle.svg';

// Homepage
import illustrationDecentralised from './homepage/illustration-decentralised.svg';
import illustrationEcoFriendly from './homepage/illustration-eco-friendly.svg';
import illustrationFast from './homepage/illustration-fast.svg';
import illustrationHighStress from './homepage/illustration-high-stress.svg';
import illustrationHashnet from './homepage/illustration-hashnet.png';

// Hashnet
import illustrationHashnetScalability from './hashnet/visuals/hashnet-scalability.svg';
import illustrationHashnetSecurity from './hashnet/visuals/hashnet-security.svg';
import illustrationHashnetSpeed from './hashnet/visuals/hashnet-speed.svg';
import illustrationHashnetFairness from './hashnet/visuals/hashnet-fairness.svg';
import illustrationHashnetAnimation from './hashnet/visuals/hashnet-animation.svg';
import iconHashnetGovernance from './hashnet/icons/icon-governance.svg';
import iconHashnetInstant from './hashnet/icons/icon-instant.svg';
import iconHashnetPrivacy from './hashnet/icons/icon-privacy.svg';
import iconHashnetTreasury from './hashnet/icons/icon-treasury.svg';
import iconHashnetCpu from './hashnet/icons/icon-cpu.svg';
import iconHashnetRam from './hashnet/icons/icon-ram.svg';
import iconHashnetDisk from './hashnet/icons/icon-disk.svg';
import iconHashnetGlobe from './hashnet/icons/icon-globe.svg';
import iconHashnetPin from './hashnet/icons/icon-pin.svg';

// Token metrics
import iconSaleGenerated from './sale/icons/icon-generation.svg';
import iconSaleHardcap from './sale/icons/icon-hard-cap.svg';
import iconSaleSoftcap from './sale/icons/icon-soft-cap.svg';
import iconSaleTolar from './sale/icons/icon-tolar.svg';

// Staking
import iconCircleGraph from './staking/icons/circle-graph.svg';
import iconQuestionMark from './staking/icons/question-mark.svg';
import iconMoneyTime from './staking/icons/money-time.svg';
import iconGlobeDot from './staking/icons/globe-dot.svg';

// partners
import partnersAgfund from './partners/agfund.png';
import partnersBcapital from './partners/bcapital.png';
import partnersBigCoin from './partners/bigcoin.png';
import partnersBittrafic from './partners/bittrafic.png';
import partnersBlackCastle from './partners/black-castle.png';
import partnersBlockground from './partners/blockground.png';
import partnersBlueBlock from './partners/blue_block.png';
import partnersBlockoxFund from './partners/blockoxfund.png';
import partnersBoosto from './partners/boosto.png';
import partnersCarnabyCapital from './partners/carnaby-capital.png';
import partnersCotrguli from './partners/cotrugli.png';
import partnersCryptoCalibur from './partners/crypto-calibur.png';
import partnersDiddyCarter from './partners/diddy-carter.png';
import partnersEclecticDigital from './partners/eclectic.digital.png';
import partnersEmares from './partners/emares.png';
import partnersGCCapital from './partners/gc-capital.png';
import partnersIcoDogTolar from './partners/icodog-tolar.png';
import partnersMoneyq from './partners/moneyq.png';
import partnersPodCapital from './partners/pod-capital.png';
import partners4thPillar from './partners/the4thpillar.png';
import partnersWandarin from './partners/wandarin.png';

export const images = {
  complexLogo,
  simpleLogo,
  patternBg,
  dotsCircle,
  illustrationDecentralised,
  illustrationEcoFriendly,
  illustrationFast,
  illustrationHighStress,
  illustrationHashnet,
  illustrationHashnetScalability,
  illustrationHashnetAnimation,
  illustrationHashnetSecurity,
  illustrationHashnetSpeed,
  illustrationHashnetFairness,
  iconHashnetGovernance,
  iconHashnetInstant,
  iconHashnetPrivacy,
  iconHashnetTreasury,
  iconHashnetCpu,
  iconHashnetRam,
  iconHashnetDisk,
  iconHashnetGlobe,
  iconHashnetPin,
  iconSaleGenerated,
  iconSaleHardcap,
  iconSaleTolar,
  iconSaleSoftcap,
  iconCircleGraph,
  iconQuestionMark,
  iconMoneyTime,
  iconGlobeDot,
  partnersAgfund,
  partnersBcapital,
  partnersBigCoin,
  partnersBittrafic,
  partnersBlackCastle,
  partnersBlockground,
  partnersBlueBlock,
  partnersBlockoxFund,
  partnersBoosto,
  partnersCarnabyCapital,
  partnersCotrguli,
  partnersCryptoCalibur,
  partnersDiddyCarter,
  partnersEclecticDigital,
  partnersEmares,
  partnersGCCapital,
  partnersIcoDogTolar,
  partnersMoneyq,
  partnersPodCapital,
  partners4thPillar,
  partnersWandarin,
};
