import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ReactComponent as IconClap } from 'main/assets/icons/icon-clap.svg';
import { ReactComponent as IconBubble } from 'main/assets/icons/icon-bubble.svg';
import { ReactComponent as IconShare } from 'main/assets/icons/icon-share.svg';

interface Post {
  title: string;
  pubDate: string;
  link: string;
  guid: string;
  author: string;
  thumbnail: string;
  content: string;
  description: string;
  categories: string;
}

export const BlogPost: React.FC = () => {
  const [postContent, setPostContent] = useState<Post>();

  const params: any = useParams();

  useEffect(() => {
    fetch(
      'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/tolarhashnetdev',
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.items && params?.id) {
          const thisPost = res.items.find(
            (item: Post) =>
              item.title.split(' ').join('-').toLowerCase() === params.id,
          );

          if (thisPost) {
            setPostContent(thisPost);
          }
        }
      });
  }, []);

  if (!postContent) {
    return null;
  }

  return (
    <div className="blog-post">
      <div className="wrapper">
        <div className="blog-post__header u-type--center">
          <p className="blog-post__date u-type--primary u-type--upper u-type--eta u-type--typography--compressed">
            {format(new Date(postContent.pubDate), 'LLL dd, y')}
          </p>
          <h1 className="blog-post__title">{postContent.title}</h1>
        </div>
      </div>
      <div className="blog-post__content">
        <div className="wrapper wrapper--small">
          <div
            className="formatted"
            dangerouslySetInnerHTML={{ __html: postContent.content }}
          ></div>

          <footer className="blog-post__footer">
            <a
              href={postContent.guid}
              className="u-display--b blog-post__action blog-post__like"
            >
              <IconClap />
            </a>
            <a
              href={postContent.guid}
              className="u-display--b blog-post__action blog-post__comment"
            >
              <IconBubble />
            </a>
            <a
              href={postContent.guid}
              className="u-display--b blog-post__action blog-post__share"
            >
              <IconShare />
            </a>
          </footer>
        </div>
      </div>
    </div>
  );
};
