import { Link } from 'react-router-dom';
import { images } from 'main/assets';
import ScrollMagic from 'scrollmagic';
import anime from 'animejs';

import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import { Portal, Modal, SEO } from 'main/components';
import React from 'react';

interface HomepageState {
  isModalOpen: boolean;
}
export class Homepage extends React.Component<HomepageState> {
  state = {
    isModalOpen: false,
  };

  // refs for getting the height of an element on resize
  private featuresRef: React.RefObject<HTMLElement>;

  // SEO props
  private seoProps = {
    SEOTitle: 'Tolar - Next-gen cryptocurrency',
    SEODescription:
      'Tolar is a community governed crypto-currency featuring scalable, fast, secure, and fair transactions.',
    SEOUrl: 'https://tolar.io',
  };
  // ScrollMagic scenes
  private featuresScene = new ScrollMagic.Scene();

  // ScrollMagic controller
  private scrollMagicController = new ScrollMagic.Controller({
    globalSceneOptions: { triggerHook: '0' },
  });

  constructor(props: any) {
    super(props);
    this.featuresRef = React.createRef();
  }

  handleSectionResize = () => {
    const widget = document.getElementById('widget');
    const widgetHeight = (widget && widget.offsetHeight) || 600;
    this.featuresScene.duration(
      this.featuresRef.current!.clientHeight + widgetHeight,
    );
  };

  setScrollMagicScenes = () => {
    const widget = document.getElementById('widget');
    const widgetHeight = (widget && widget.offsetHeight) || 600;
    const containerHeight = this.featuresRef.current!.offsetHeight;

    this.featuresScene
      .triggerElement('.js-section-features')
      .setClassToggle('.container', 'light')
      .duration(containerHeight + widgetHeight)
      .addTo(this.scrollMagicController);
  };

  componentDidMount() {
    configureAnchors({ offset: -1 });
    this.setScrollMagicScenes();
    window.addEventListener('resize', this.handleSectionResize);

    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.hero__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.hero__claim',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.home__header__button',
        opacity: [0, 1],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSectionResize);
    this.scrollMagicController.destroy(true);
  }

  componentDidUpdate() {
    this.setScrollMagicScenes();
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  render() {
    const { isModalOpen } = this.state;

    return (
      <main>
        <SEO {...this.seoProps} />
        {isModalOpen && (
          <Portal>
            <Modal onClose={this.toggleModal}>
              <iframe
                title="Youtube"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/fO58E9Y4AOY?rel=0&amp;controls=0&amp;showinfo=0"
                frameBorder="0"
              />
            </Modal>
          </Portal>
        )}
        <section className="hero js-section-hero section--dark">
          <ScrollableAnchor id={'hero'}>
            <div className="wrapper wrapper--medium">
              <div className="hero__content">
                <h1 className="hero__title u-type--upper u-type--weight--thin u-type--secondary">
                  <span className="hero__subtitle u-type--spacing--extra-wide u-type--typography--compressed u-opacity--70 u-display--b">
                    4.0 blockchain revolution
                  </span>
                  <span className="hero__maintitle u-type--weight--ultra u-type--negative">
                    HashNET
                  </span>
                </h1>
                <p className="hero__claim u-type--zeta u-opacity--60 u-type--secondary u-type--typography--wide">
                  HashNET main net is alive and kicking, featuring scalable,
                  fast, secure and fair transactions. It employs Distributed
                  Ledger Technology (DLT) and consensus algorithm that keep all
                  the positive characteristics of a blockchain technology while
                  increasing throughput and lowering time to finality.
                </p>
                <a
                  href="mailto:info@tolar.io"
                  className="btn btn--primary btn--lrg home__header__button"
                >
                  Contact us
                </a>
              </div>
            </div>
          </ScrollableAnchor>
        </section>
        <section className="home__section--hashnet js-section-hashnet section--dark">
          <ScrollableAnchor id={'hashnet'}>
            <div className="wrapper">
              <div className="home__section--hashnet__video">
                <img src={images.illustrationHashnet} alt="What is Hashnet?" />
                <div className="home__section--hashnet__video__label">
                  <p className="u-type--micro u-type--upper u-type--spacing--extra-wide u-opacity--70">
                    Find out more
                  </p>
                  <span className="u-type--zeta u-type--typography--wide">
                    What is Hashnet?
                  </span>
                </div>
              </div>
              <div className="home__section--hashnet__heading parallax-item">
                <p className="u-type--epsilon u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
                  Hashnet
                </p>
                <h1 className="u-type--delta u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                  Transaction superhighway designed
                  <span className="u-type--spacing--extra-wide u-type--typography--compressed u-opacity--70 u-display--b u-type--eta u-type--weight--light">
                    on blockchain and beyond
                  </span>
                </h1>
                <Link to="hashnet" className="btn btn--primary btn--lrg">
                  I want to know more
                </Link>
              </div>
            </div>
          </ScrollableAnchor>
        </section>
        <section
          ref={this.featuresRef}
          className="home__section--features js-section-features section--light"
        >
          <ScrollableAnchor id={'features'}>
            <div className="wrapper">
              <div className="features__content">
                <div className="features__content__item">
                  <img
                    src={images.illustrationEcoFriendly}
                    alt="100% secure and eco friendly"
                  />
                  <h1 className="features__content__item__title u-type--epsilon u-type--upper u-type--weight--ultra">
                    100% secure &amp; eco friendly
                  </h1>
                  <p className="u-opacity--60 u-type--zeta">
                    HashNET is 100% secure. HashNET can’t be hacked due to the
                    power of distributed algorithm. Also, combining
                    Proof-of-Stake and masternode creates transparency, since
                    the participants are compensated for correct voting. Network
                    is using Proof-of-Stake with masternodes, which eliminates
                    the need for a massive energy consumption.
                  </p>
                </div>
                <div className="features__content__item parallax-item">
                  <img
                    src={images.illustrationFast}
                    alt="Fast. Over 20,000 TPS fast."
                  />
                  <h1 className="features__content__item__title u-type--epsilon u-type--upper u-type--weight--ultra">
                    Fast. Over 20,000 TPS fast.
                  </h1>
                  <p className="u-opacity--60 u-type--zeta">
                    HashNET is fast, because it doesn’t require miners to create
                    a chain of blocks in order to record transactions. HashNET
                    uses Proof-of-Stake algorithm combined with masternode to
                    reach distributed consensus. The process of receiving and
                    recording occurs as soon as transaction takes place, which
                    allows the final confirmation to be ready instantly.
                  </p>
                </div>
                <div className="features__content__item">
                  <img
                    src={images.illustrationHighStress}
                    alt="Ready for high stress"
                  />
                  <h1 className="features__content__item__title u-type--epsilon u-type--upper u-type--weight--ultra">
                    Ready for high stress
                  </h1>
                  <p className="u-opacity--60 u-type--zeta">
                    HashNET is built to support over 20,000 transactions per
                    second, compared to Bitcoin, which can only manage 7
                    transactions per second. Even with a large increase in
                    number of nodes, HashNET network will be able to process all
                    transactions in seconds.
                  </p>
                </div>
                <div className="features__content__item parallax-item">
                  <img
                    src={images.illustrationDecentralised}
                    alt="Fair and decentralized"
                  />
                  <h1 className="features__content__item__title u-type--epsilon u-type--upper u-type--weight--ultra">
                    Fair &amp; decentralized
                  </h1>
                  <p className="u-opacity--60 u-type--zeta">
                    HashNET is fair, because no individual can manipulate the
                    order of transactions. With HashNET, every participant can
                    contribute by voting and there is no way for the individual
                    to affect the consensus order.
                  </p>
                </div>
              </div>
            </div>
          </ScrollableAnchor>
        </section>
      </main>
    );
  }
}
