import { MEMBERS } from '../consts';
import ShowMore from 'react-show-more';
import React from 'react';

interface TeamMemberState {
  imageUrl: {
    default: string;
  };
  name: string;
  description: string;
  position: string;
}
export class TeamMember extends React.Component<
  Record<string, unknown>,
  TeamMemberState
> {
  state = {
    imageUrl: {
      default: '',
    },
    name: '',
    description: '',
    position: '',
  };

  getRandomMember = () => {
    let i = MEMBERS.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = MEMBERS[i];
      MEMBERS[i] = MEMBERS[j];
      MEMBERS[j] = temp;
    }
    return MEMBERS[i];
  };

  componentDidMount() {
    const { description, imageUrl, name, position } = this.getRandomMember();
    this.setState({ description, imageUrl, name, position });
  }

  render() {
    const { imageUrl, description, position, name } = this.state;
    return (
      <div className="team__member-info">
        <div className="team__member-info__image">
          <img src={imageUrl.default} alt={name} />
        </div>
        <div className="team__member-info__content">
          <p className="team__member-info__position u-type--micro u-type--spacing--extra-wide u-type--upper u-opacity--60">
            {position}
          </p>
          <h3 className="team__member-info__name u-type--delta">{name}</h3>
          <div className="team__member-info__description u-type--eta u-opacity--60">
            <ShowMore anchorClass="person__more u-type--upper u-type--micro u-display--b u-type--spacing--wide">
              {description}
            </ShowMore>
          </div>
        </div>
      </div>
    );
  }
}
