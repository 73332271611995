import ScrollMagic from 'scrollmagic';
import ScrollableAnchor from 'react-scrollable-anchor';

import { HashNetBizz, HashnetTech, Advisors, FoundingBoard } from 'main/consts';
import { Person, SEO } from 'main/components';
import { Member } from 'main/models';
import anime from 'animejs';
import React from 'react';

export class Team extends React.Component {
  // refs for getting the height of an element on resize
  private hashnetTechRef: React.RefObject<HTMLElement>;
  private hashnetAdvisorsRef: React.RefObject<HTMLElement>;

  // SEO props
  private seoProps = {
    SEOTitle: 'Tolar - Team',
    SEODescription:
      'Tolar is a community governed crypto-currency featuring scalable, fast, secure, and fair transactions.',
    SEOUrl: 'dummyUrl',
  };

  // ScrollMagic scenes
  private hashnetTechScene = new ScrollMagic.Scene();
  private hashnetAdvisorsScene = new ScrollMagic.Scene();

  // ScrollMagic controller
  private scrollMagicController = new ScrollMagic.Controller({
    globalSceneOptions: { triggerHook: '0' },
  });

  constructor(props: any) {
    super(props);
    this.hashnetTechRef = React.createRef();
    this.hashnetAdvisorsRef = React.createRef();
  }

  handleSectionResize = () => {
    this.hashnetTechScene.duration(this.hashnetTechRef.current!.clientHeight);
    this.hashnetAdvisorsScene.duration(
      this.hashnetAdvisorsRef.current!.clientHeight,
    );
  };

  setScrollMagicScenes = () => {
    this.hashnetTechScene
      .triggerElement('.js-hashnet-tech')
      .setClassToggle('.container', 'light')
      .duration(this.hashnetTechRef.current!.clientHeight)
      // .addIndicators()
      .addTo(this.scrollMagicController);

    this.hashnetAdvisorsScene
      .triggerElement('.js-hashnet-advisors')
      .setClassToggle('.container', 'light')
      .duration(this.hashnetAdvisorsRef.current!.clientHeight)
      // .addIndicators()
      .addTo(this.scrollMagicController);
  };

  renderPeopleCards = (members: Member[]) => {
    const components = [];
    for (const member of members) {
      components.push(<Person member={member} key={member.name} />);
    }

    return components;
  };
  componentDidMount() {
    this.setScrollMagicScenes();
    window.addEventListener('resize', this.handleSectionResize);

    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.contentpage__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__subtitle',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__header__toc',
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }

  getFoundingMembers = () => {
    return this.renderPeopleCards(FoundingBoard);
  };

  getHashnetTechMembers = () => {
    return this.renderPeopleCards(HashnetTech);
  };

  getHashnetBizzMembers = () => {
    return this.renderPeopleCards(HashNetBizz);
  };

  getAdvisors = () => {
    return this.renderPeopleCards(Advisors);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSectionResize);
    this.scrollMagicController.destroy(true);
  }
  render() {
    return (
      <section className="contentpage team section--dark">
        <SEO {...this.seoProps} />
        <header className="contentpage__header">
          <div className="wrapper wrapper--medium">
            <div className="contentpage__title">
              <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
                Meet the
              </p>
              <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-spc-bottom--lrg">
                Tolar team
              </h1>
            </div>
            <p className="contentpage__subtitle u-type--zeta u-type--secondary u-type--typography--wide u-opacity--70">
              Tolar brings together experts from various business, IT and legal
              backgrounds. We combine experience with diversity, expertise, and
              most importantly, passion for blockchain technology.
            </p>

            <ul className="contentpage__header__toc">
              <li>
                <a href="#foundation-board">
                  <span className="contentpage__header__toc__number">01</span>
                  <span className="contentpage__header__toc__title">
                    Foundation Board
                  </span>
                </a>
              </li>
              <li>
                <a href="#hashnet-tech">
                  <span className="contentpage__header__toc__number">02</span>
                  <span className="contentpage__header__toc__title">
                    HashNET tech
                  </span>
                </a>
              </li>
              <li>
                <a href="#hashnet-bizz">
                  <span className="contentpage__header__toc__number">03</span>
                  <span className="contentpage__header__toc__title">
                    HashNET bizz
                  </span>
                </a>
              </li>
              <li>
                <a href="#advisors">
                  <span className="contentpage__header__toc__number">04</span>
                  <span className="contentpage__header__toc__title">
                    Advisors
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </header>
        <main>
          <ScrollableAnchor id={'foundation-board'}>
            <section className="team__intro">
              <header className="team__intro__header">
                <div className="wrapper">
                  <div className="team__intro__header__content">
                    <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                      01
                    </div>
                    <h2 className="u-type--gamma u-type--upper u-type--weight--thin">
                      <span className="u-type--weight--ultra">Foundation</span>
                      <br />
                      board
                    </h2>
                  </div>
                  <div className="team__intro__header__action">
                    <button className="btn btn--primary btn--med">
                      Join the team
                    </button>
                  </div>
                </div>
              </header>
              <div className="wrapper">
                <div className="person__wrapper person__wrapper--negative">
                  {this.getFoundingMembers()}
                </div>
              </div>
            </section>
          </ScrollableAnchor>
          <section
            ref={this.hashnetTechRef}
            className="js-hashnet-tech team__section--tech section--light"
          >
            <ScrollableAnchor id={'hashnet-tech'}>
              <div className="wrapper">
                <header className="team__intro__header">
                  <div className="team__intro__header__content">
                    <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                      02
                    </div>
                    <h2 className="u-type--gamma u-type--upper u-type--weight--thin">
                      <span className="u-type--weight--ultra">Hashnet</span>
                      <br />
                      Tech
                    </h2>
                  </div>
                  <div className="team__intro__header__action">
                    <button className="btn btn--primary btn--med">
                      Join the team
                    </button>
                  </div>
                </header>
                <div className="person__wrapper">
                  {this.getHashnetTechMembers()}
                </div>
              </div>
            </ScrollableAnchor>
          </section>
          <section className="team__section--bizz">
            <ScrollableAnchor id={'hashnet-bizz'}>
              <div className="wrapper">
                <header className="team__intro__header">
                  <div className="team__intro__header__content">
                    <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                      03
                    </div>
                    <h2 className="u-type--gamma u-type--upper u-type--weight--thin">
                      <span className="u-type--weight--ultra">Hashnet</span>
                      <br />
                      Bizz
                    </h2>
                  </div>
                  <div className="team__intro__header__action">
                    <button className="btn btn--primary btn--med">
                      Join the team
                    </button>
                  </div>
                </header>
                <div className="person__wrapper person__wrapper--negative">
                  {this.getHashnetBizzMembers()}
                </div>
              </div>
            </ScrollableAnchor>
          </section>
          <section
            ref={this.hashnetAdvisorsRef}
            className="js-hashnet-advisors team__section--advisors section--light"
          >
            <ScrollableAnchor id={'advisors'}>
              <div className="wrapper">
                <header className="team__intro__header">
                  <div className="team__intro__header__content">
                    <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                      04
                    </div>
                    <h2 className="u-type--gamma u-type--upper u-type--weight--thin">
                      <span className="u-type--weight--ultra">Advisors</span>
                    </h2>
                  </div>
                </header>
                <div className="person__wrapper">{this.getAdvisors()}</div>
              </div>
            </ScrollableAnchor>
          </section>
        </main>
      </section>
    );
  }
}
