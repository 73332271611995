interface BlogPost {
  blogData:
    | {
        title: string;
        pubDate: string;
        link: string;
        guid: string;
        author: string;
        thumbnail: string;
        description: string;
        categories: string;
      }
    | undefined;
}

export const BlogArticle: React.FC<BlogPost> = ({ blogData }) => {
  if (!blogData) {
    return null;
  }

  const { pubDate, title, thumbnail, link } = blogData;

  return (
    <article className="blog__article">
      <a href={link} className="blog__article__image">
        <img src={thumbnail} alt="" />
      </a>
      <p className="blog__article__date  u-type--primary u-type--upper u-type--nano ">
        {pubDate}
      </p>
      <a href={link} className="blog__article__title u-type--delta">
        {title}
      </a>
    </article>
  );
};
