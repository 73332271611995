import React from 'react';
import * as ReactDOM from 'react-dom';
interface Props {
  children: any;
}

// This is a sibling of the component of the DOM element that we
// used to render our main app
const portal = document.getElementById('modal');

export class Portal extends React.Component<Props> {
  private modalElement: Element;
  constructor(props: Props) {
    super(props);
    this.modalElement = document.createElement('div');
  }

  componentDidMount() {
    if (portal) {
      portal.appendChild(this.modalElement);
    }
  }
  componentWillUnmount() {
    if (portal) {
      portal.removeChild(this.modalElement);
    }
  }
  render() {
    const { children } = this.props;
    // Use a portal to render the children into the element
    return ReactDOM.createPortal(children, this.modalElement);
  }
}
