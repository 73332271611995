import { SEO } from 'main/components';
import ScrollMagic from 'scrollmagic';
import ScrollableAnchor from 'react-scrollable-anchor';
import { configureAnchors } from 'react-scrollable-anchor';
import anime from 'animejs';
import React from 'react';

configureAnchors({
  offset: 0,
  scrollDuration: 300,
});

// require('debug.addIndicators');

export class Transparency extends React.Component {
  // refs for getting the height of an element on resize
  private eligibilityRef: React.RefObject<HTMLElement>;
  private governanceRef: React.RefObject<HTMLElement>;

  // SEO props
  private seoProps = {
    SEOTitle: 'Tolar - Transparency',
    SEODescription:
      'Tolar is a community governed crypto-currency featuring scalable, fast, secure, and fair transactions.',
    SEOUrl: 'dummyUrl',
  };
  // ScrollMagic scenes
  private governanceScene = new ScrollMagic.Scene();

  // ScrollMagic controller
  private scrollMagicController = new ScrollMagic.Controller({
    globalSceneOptions: { triggerHook: '0' },
  });

  constructor(props: any) {
    super(props);
    this.eligibilityRef = React.createRef();
    this.governanceRef = React.createRef();
  }

  setScrollMagicScenes = () => {
    this.governanceScene
      .triggerElement('.js-section-governance')
      .setClassToggle('.container', 'light')
      .duration(
        this.governanceRef.current!.clientHeight +
          this.eligibilityRef.current!.clientHeight,
      )
      // .addIndicators()
      .addTo(this.scrollMagicController);
  };

  handleSectionResize = () => {
    this.governanceScene.duration(
      this.governanceRef.current!.clientHeight +
        this.eligibilityRef.current!.clientHeight,
    );
  };

  componentDidMount() {
    this.setScrollMagicScenes();
    window.addEventListener('resize', this.handleSectionResize);

    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.contentpage__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__subtitle',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__header__toc',
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSectionResize);
    this.scrollMagicController.destroy(true);
  }
  render() {
    return (
      <section className="contentpage transparency section--dark">
        <SEO {...this.seoProps} />
        <header className="contentpage__header">
          <div className="wrapper wrapper--medium">
            <div className="contentpage__title">
              <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
                Hashnet
              </p>
              <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-type--negative u-spc-bottom--lrg u-type--wrap">
                Transparency
              </h1>
            </div>
            <p className="contentpage__subtitle u-type--zeta u-type--secondary u-type--typography--wide u-opacity--70">
              We take pride in being open and transparent with our community and
              stakeholders. We encourage you to read our whitepaper and learn
              more about our governance system to understand how you can
              participate in the improvement of our HashNET network.
            </p>

            <ul className="contentpage__header__toc">
              <li>
                <a href="#whitepaper">
                  <span className="contentpage__header__toc__number">01</span>
                  <span className="contentpage__header__toc__title">
                    Tolar whitepaper
                  </span>
                </a>
              </li>
              <li>
                <a href="#governance-system">
                  <span className="contentpage__header__toc__number">02</span>
                  <span className="contentpage__header__toc__title">
                    Governance system
                  </span>
                </a>
              </li>
              <li>
                <a href="#eligibility">
                  <span className="contentpage__header__toc__number">03</span>
                  <span className="contentpage__header__toc__title">
                    Tender eligibility
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </header>
        <main>
          <ScrollableAnchor id={'whitepaper'}>
            <section className="transparency__whitepaper">
              <div className="wrapper">
                <div className="transparency__whitepaper__wrapper">
                  <header className="transparency__whitepaper__header">
                    <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                      01
                    </div>
                    <h1 className="u-type--beta u-type--upper u-type--weight--thin">
                      <span className="u-type--weight--ultra">Tolar</span>
                      <br />
                      whitepaper
                    </h1>
                  </header>
                  <div className="transparency__whitepaper__content">
                    <p className="u-opacity--60 transparency__whitepaper__content__split">
                      HashNET is a scalable, efficient and high-impact
                      decentralised solution to social innovation challenges
                      leveraging Distributed Ledger Technology (DLTs). This
                      revolutionary algorithm features faster transaction time,
                      masternodes and will have a community-governed
                      cryptocurrency – Tolar. HashNET algorithm is designed to
                      solve the blockchain scalability problem. It is a new
                      consensus substitute to the blockchain, keeping all the
                      good characteristics from blockchain (decentralized,
                      transparent, pseudo-anonymous) and increasing speed to
                      more than 20,000 TPS. Our whitepaper explains how HashNET
                      technology works in great detail, it gives you examples
                      and real case scenarios to help you better understand the
                      system behind it. Also, our whitepaper tells you the story
                      of how the project was created, the idea behind it, our
                      successes so far and our plans for the future.
                    </p>
                    <a
                      href={require('../../../main/assets/pdf/Whitepaper-Tolar.pdf')}
                      target="_new"
                      className="btn btn--primary btn--lrg u-spc-top--lrg"
                    >
                      Download whitepaper
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </ScrollableAnchor>

          <div className="">
            <section
              ref={this.governanceRef}
              className="transparency__governance js-section-governance section--light"
            >
              <ScrollableAnchor id={'governance-system'}>
                <div className="wrapper">
                  <div className="transparency__governance__wrapper">
                    <header className="transparency__governance__header">
                      <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                        02
                      </div>
                      <h1 className="u-type--beta u-type--upper u-type--weight--thin">
                        <span className="u-type--weight--ultra">Tolar</span>{' '}
                        governance system
                      </h1>
                      <p className="transparency__governance__paragraph u-type--zeta u-opacity--60">
                        Tolar has a governance system (Magnus Consilium) in
                        which stakeholders and investors will have the power of
                        voting on proposals in order to participate in
                        improvements of HashNET network. They can participate in
                        the following two ways:
                      </p>
                    </header>
                    <div className="transparency__governance__content">
                      <div className="transparency__governance__content__item">
                        <svg
                          width="39"
                          height="46"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            transform="translate(.5 .5)"
                            stroke="#00BDDE"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <path strokeLinecap="square" d="M34 3h4v42H0V3h4" />
                            <path
                              opacity=".4"
                              strokeLinecap="square"
                              d="M29.03 42H3V0"
                            />
                            <path
                              d="M10 1v10h5a4 4 0 0 1 8 0h5V1H10zM8 20h22"
                              strokeLinecap="square"
                            />
                            <circle
                              fill="#FFF"
                              fillRule="nonzero"
                              cx="29.5"
                              cy="19.5"
                              r="2.5"
                            />
                            <path d="M8 28h22M8 36h22" strokeLinecap="square" />
                          </g>
                        </svg>
                        <h2 className="u-type--epsilon u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-type--spacing--narrow">
                          Tenders
                        </h2>
                        <p className="u-type--zeta u-opacity--60">
                          Tenders are budgeting plans which will be submitted to
                          the Magnus Consilium where every proposal will need to
                          have a majority of votes in order to pass.
                        </p>
                      </div>
                      <div className="transparency__governance__content__item">
                        <svg
                          width="45"
                          height="45"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            transform="translate(.5 .5)"
                            stroke="#00BDDE"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <path
                              d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0c8.202 0 15.25 4.937 18.336 12.001"
                              strokeLinecap="square"
                            />
                            <path
                              d="M19.854 36.5c-9.113 0-16.5-7.387-16.5-16.5s7.387-16.5 16.5-16.5c6.767 0 12.582 4.073 15.128 9.9"
                              opacity=".3"
                              strokeLinecap="square"
                            />
                            <circle
                              fill="#FFF"
                              fillRule="nonzero"
                              cx="19"
                              cy="40"
                              r="2.5"
                            />
                            <path strokeLinecap="square" d="M20 10v10H10" />
                            <ellipse
                              strokeLinecap="square"
                              cx="35"
                              cy="22"
                              rx="9"
                              ry="4"
                            />
                            <path d="M26 22v6c0 2.209 4.029 4 9 4s9-1.791 9-4v-6" />
                            <path d="M26 28v6c0 2.209 4.029 4 9 4s9-1.791 9-4v-6" />
                            <path d="M26 34v6c0 2.209 4.029 4 9 4s9-1.791 9-4v-6" />
                          </g>
                        </svg>
                        <h2 className="u-type--epsilon u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-type--spacing--narrow">
                          Proof of stake (PoS)
                        </h2>
                        <p className="u-type--zeta u-opacity--60">
                          If a user decides to lock-in his coins, he/she will
                          receive interest based on the number of coins staked,
                          and the duration of time the coins were stored.
                        </p>
                      </div>
                    </div>
                    {/* <button className="btn btn--primary btn--med">
                      Learn more
                    </button> */}
                  </div>
                </div>
              </ScrollableAnchor>
            </section>
            <section
              ref={this.eligibilityRef}
              className="transparency__eligibility section--light"
            >
              <ScrollableAnchor id={'eligibility'}>
                <div className="wrapper">
                  <div className="transparency__eligibility__wrapper">
                    <header className="transparency__eligibility__header">
                      <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                        03
                      </div>
                      <h1 className="u-type--beta u-type--upper u-type--weight--thin">
                        <span className="u-type--weight--ultra">Tender</span>{' '}
                        eligibility
                      </h1>
                      <p className="transparency__governance__paragraph u-type--zeta u-opacity--60">
                        Tenders and budget plans are available to all Tolar
                        holders. Our governance system (Magnus Consilium) will
                        select projects for voting, based on a solution that
                        best meets the following criteria:
                      </p>
                    </header>
                    <div className="transparency__eligibility__content">
                      <div className="transparency__eligibility__content__item">
                        <svg
                          width="33"
                          height="33"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g stroke="#00BDDE" fill="none" fillRule="evenodd">
                            <path d="M8.5 27.5l3.4 1.7c1.7.8 3.6 1.3 5.6 1.3h10c1.7 0 3.2-1.2 3.5-3l1.5-12c0-1.7-1.3-3-3-3h-8v-5c0-2.9-1.8-5.9-4.7-6.8-.6-.2-1.3.3-1.3 1v5.8l-7 9" />
                            <path
                              strokeLinecap="square"
                              d="M2.5 13.5h6v17h-6z"
                            />
                            <path
                              opacity=".3"
                              strokeLinecap="square"
                              d="M.5 15.5h6v17h-6z"
                            />
                          </g>
                        </svg>
                        <h2 className="u-type--epsilon u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-type--spacing--narrow">
                          Social impact
                        </h2>
                        <p className="u-type--zeta u-opacity--60">
                          Potential impact achieved by the implementation of the
                          solution.
                        </p>
                      </div>
                      <div className="transparency__eligibility__content__item">
                        <svg
                          width="49"
                          height="53"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            transform="translate(0 1)"
                            stroke="#00BDDE"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g transform="translate(.5 3.5)">
                              <path d="M22 16v8l-8.4 6.3M22 24l8.4 6.3" />
                              <circle
                                strokeLinecap="square"
                                cx="22"
                                cy="8"
                                r="8"
                              />
                              <circle
                                strokeLinecap="square"
                                cx="8"
                                cy="36"
                                r="8"
                              />
                              <circle
                                strokeLinecap="square"
                                cx="36"
                                cy="36"
                                r="8"
                              />
                            </g>
                            <circle opacity=".4" cx="22.5" cy="11.5" r="11.5" />
                            <circle opacity=".4" cx="36.5" cy="39.5" r="11.5" />
                          </g>
                        </svg>
                        <h2 className="u-type--epsilon u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-type--spacing--narrow u-type--wrap">
                          Decentralisation and governance
                        </h2>
                        <p className="u-type--zeta u-opacity--60">
                          Allows voters to vote on a future network developments
                        </p>
                      </div>
                      <div className="transparency__eligibility__content__item">
                        <svg
                          width="49"
                          height="45"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            transform="translate(.5 .5)"
                            stroke="#00BDDE"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <path strokeLinecap="square" d="M4 27v14h38V9h-6" />
                            <path
                              opacity=".5"
                              strokeLinecap="square"
                              d="M19 44h26V8"
                            />
                            <circle opacity=".5" cx="45" cy="5" r="2.5" />
                            <path
                              d="M0 14L16 0v8c10.417 0 18 3.833 18 20-3.75-4.917-7.333-8.083-18-8v8L0 14z"
                              strokeLinecap="square"
                            />
                          </g>
                        </svg>
                        <h2 className="u-type--epsilon u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-type--spacing--narrow">
                          Outreach
                        </h2>
                        <p className="u-type--zeta u-opacity--60">
                          Adding value by spreading a word out to the community.
                        </p>
                      </div>
                      <div className="transparency__eligibility__content__item">
                        <svg
                          width="44"
                          height="56"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            transform="translate(1)"
                            stroke="#00BDDE"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <g
                              transform="translate(6.5 4.5)"
                              strokeLinecap="square"
                            >
                              <path d="M0 0h36v44H0z" />
                              <path d="M23 33.672c0-1.208-.529-2.357-1.476-3.108C20.078 29.416 17.57 28 14 28s-6.078 1.416-7.524 2.564C5.529 31.315 5 32.464 5 33.672V35h18v-1.328z" />
                              <circle cx="14" cy="18" r="5" />
                              <path d="M28 35h3v-1.328c0-1.208-.529-2.357-1.476-3.108a11.77 11.77 0 0 0-3.525-1.918M22 13a5 5 0 0 1 0 10" />
                            </g>
                            <path strokeLinecap="square" d="M2.5 49.5V.5h15" />
                            <circle cx="2.5" cy="52.5" r="2.5" />
                          </g>
                        </svg>
                        <h2 className="u-type--epsilon u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-type--spacing--narrow u-type--wrap">
                          Contribution
                        </h2>
                        <p className="u-type--zeta u-opacity--60">
                          Awarding improvements of Tolar network.
                        </p>
                      </div>
                      <div className="transparency__eligibility__content__item">
                        <svg
                          width="43"
                          height="46"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g stroke="#00BDDE" fill="none" fillRule="evenodd">
                            <path d="M.5 8.5h9c6.627 0 12 5.373 12 12v20M42.5 8.5h-9c-6.627 0-12 5.373-12 12v20" />
                            <path
                              strokeLinecap="square"
                              d="M8.5 16.5l-8-8 8-8M34.5 16.5l8-8-8-8"
                            />
                            <circle cx="21.5" cy="42.5" r="2.5" />
                            <path
                              d="M33.5 12.5c-6.627 0-9 4.373-9 11v12"
                              opacity=".5"
                            />
                          </g>
                        </svg>
                        <h2 className="u-type--epsilon u-type--upper u-type--weight--ultra u-spc-bottom--lrg u-type--spacing--narrow u-type--wrap">
                          Extensiveness
                        </h2>
                        <p className="u-type--zeta u-opacity--60">
                          Allowing voters to make decisions on further
                          development paths.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollableAnchor>
            </section>
          </div>
        </main>
      </section>
    );
  }
}
