import React from 'react';

export const MobileNav: React.FC = () => {
  return (
    <div className="nav__links__container">
      <a
        href={require('../../../main/assets/pdf/Whitepaper-Tolar.pdf')}
        className="btn btn--primary btn--whitepaper btn--wide"
        target="_new"
      >
        Whitepaper
      </a>

      <div className="social-bar social-bar--inmenu">
        <span className="social-bar__connect u-type--micro u-opacity--60 u-type--secondary u-type--typography--wide">
          Connect with us
        </span>
        <a className="social-bar__item" href="https://t.me/tolarofficial">
          <svg
            className="social-bar__icon"
            x="0px"
            y="0px"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
          >
            <g transform="translate(0, 0)">
              <path
                d="M3.6,9.6v4.9c0,0.7,0.8,1,1.3,0.5l2.3-2.7l4.7,3.5c0.4,0.3,1,0.1,1.1-0.4L16,0.9c0.1-0.6-0.4-1-1-0.8
            L0.5,5.9c-0.5,0.2-0.6,0.9-0.2,1.3l1.6,1.2l4.6-2.2C6.9,6,7.2,6.5,6.9,6.7L3.6,9.6z"
              />
            </g>
          </svg>
          <span className="social-bar__item__label u-type--upper">
            Telegram
          </span>
        </a>
        <a className="social-bar__item" href="https://twitter.com/tolarhashnet">
          <svg className="social-bar__icon" width="16" height="16">
            <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
          </svg>
          <span className="social-bar__item__label u-type--upper">Twitter</span>
        </a>
        <a
          className="social-bar__item"
          href="https://www.facebook.com/TolarHashNET/"
        >
          <svg
            className="social-bar__icon"
            x="0px"
            y="0px"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
          >
            <g transform="translate(0, 0)">
              <path
                d="M6.02293,16L6,9H3V6h3V4c0-2.6992,1.67151-4,4.07938-4c1.15339,0,2.14468,0.08587,2.43356,0.12425v2.82082
            l-1.66998,0.00076c-1.30953,0-1.56309,0.62227-1.56309,1.53541V6H13l-1,3H9.27986v7H6.02293z"
              />
            </g>
          </svg>
          <span className="social-bar__item__label u-type--upper">
            Facebook
          </span>
        </a>
        <a
          className="social-bar__item"
          href="https://www.youtube.com/channel/UCL8ksEpe6um71pcdPi6wpXQ"
        >
          <svg
            className="social-bar__icon"
            x="0px"
            y="0px"
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
          >
            <g transform="translate(0, 0)">
              <path
                d="M15.8,4.8c-0.2-1.3-0.8-2.2-2.2-2.4C11.4,2,8,2,8,2S4.6,2,2.4,2.4C1,2.6,0.3,3.5,0.2,4.8C0,6.1,0,8,0,8
            s0,1.9,0.2,3.2c0.2,1.3,0.8,2.2,2.2,2.4C4.6,14,8,14,8,14s3.4,0,5.6-0.4c1.4-0.3,2-1.1,2.2-2.4C16,9.9,16,8,16,8S16,6.1,15.8,4.8z
            M6,11V5l5,3L6,11z"
              />
            </g>
          </svg>
          <span className="social-bar__item__label u-type--upper">Youtube</span>
        </a>
        <a
          className="social-bar__item"
          href="https://www.linkedin.com/company/tolar-io/"
        >
          <svg
            className="social-bar__icon"
            x="0px"
            y="0px"
            width="14px"
            height="14px"
            viewBox="0 0 14 14"
          >
            <g>
              <rect x="0.2" y="4.7" width="2.9" height="9.3" />
              <path d="M1.7,0C0.7,0,0,0.8,0,1.7c0,1,0.8,1.7,1.7,1.7s1.7-0.7,1.7-1.7C3.4,0.7,2.6,0,1.7,0z" />
              <path
                d="M10.5,4.4c-1.4,0-2.4,0.8-2.7,1.5H7.7V4.7H5.1V14h2.9V9.4C7.9,8.2,8.1,7,9.6,7s1.5,1.3,1.5,2.4v4.5H14V8.8
            C14,6.4,13.5,4.4,10.5,4.4z"
              />
            </g>
          </svg>
          <span className="social-bar__item__label u-type--upper">
            LinkedIn
          </span>
        </a>
      </div>
    </div>
  );
};
