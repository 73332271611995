import anime from 'animejs';

import { SEO } from 'main/components';
import React from 'react';

export class Roadmap extends React.Component {
  // SEO props
  private seoProps = {
    SEOTitle: 'Tolar - Roadmap',
    SEODescription:
      'Tolar is a community governed crypto-currency featuring scalable, fast, secure, and fair transactions.',
    SEOUrl: 'dummyUrl',
  };
  componentDidMount() {
    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.contentpage__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__subtitle',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__header__toc',
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }

  render() {
    return (
      <section className="contentpage roadmap section--dark">
        <SEO {...this.seoProps} />
        <header className="contentpage__header">
          <div className="wrapper wrapper--medium">
            <div className="contentpage__title">
              <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
                Tolar
              </p>
              <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-type--negative u-spc-bottom--lrg">
                Roadmap
              </h1>
            </div>
            <p className="contentpage__subtitle u-type--zeta u-type--secondary u-type--typography--wide u-opacity--70">
              Our roadmap is carefully designed to balance the pace of research,
              technological and business development, so that commercial
              advantage does not win over the quality and usability of our
              technology.
            </p>
          </div>
        </header>
        <main>
          <div className="roadmap__content">
            <section className="roadmap__map">
              <ul className="timeline timeline--first">
                <li className="timeline__topdecoration" />
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q2 / 2017
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    TOLAR project founded and initial funding received
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q2 / 2017
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Executive group established
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q2 / 2017
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q3 / 2017
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Blockchain Conference Rovinj
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2017
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Initial network development
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2017
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    First consensus algorithms experiments
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2017
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q1 / 2018
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Launch of the Blockchain academy
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q1 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Recruiting process of key team members has started
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q1 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Core Tolar team created with 30 members.
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q1 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Website published
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q1 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    TOLAR introduced publicly
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q1 / 2018
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q2 / 2018
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Initial version of HashNET whitepaper
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q2 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Social media presence established
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q2 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Global Tolar community started
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q2 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Start of private sale phase
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q2 / 2018
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q3 / 2018
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Finalized version of HashNET whitepaper
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Developers demonstration of HashNET
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Tolar world tour
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Pre-ICO
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    ICO
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2018
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q4 / 2018
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Exchange listing
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q4 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Developers test network scaling to 100+ nodes
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q4 / 2018
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Integration with EVM
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q4 / 2018
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q1 / 2019
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Release of public test network
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q1 / 2019
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    GUI Wallets
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q1 / 2019
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q2 / 2019
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Mainnet release
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q2 / 2019
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Chain explorer
                  </span>
                  <span className="timeline__item__circle timeline__item__circle--complete" />
                  <span className="timeline__item__month u-opacity--30">
                    Q2 / 2019
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Q3 / 2019
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Token swap to native Tolar coin
                  </span>
                  <span className="timeline__item__circle" />
                  <span className="timeline__item__month u-opacity--30">
                    Q3 / 2019
                  </span>
                </li>
              </ul>
            </section>
            <section className="roadmap__map">
              <ul className="timeline timeline--last">
                <li className="timeline__item timeline__item--title">
                  <span className="timeline__item__title">
                    <h4 className="u-type--delta u-type--upper u-type--weight--bold u-type--typography--wide u-type--negative u-opacity--15">
                      Future
                    </h4>
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Smart contracts
                  </span>
                  <span className="timeline__item__circle" />
                  <span className="timeline__item__month u-opacity--30">
                    Future development
                  </span>
                </li>
                <li className="timeline__item">
                  <span className="timeline__item__content u-type--light-color u-opacity--70">
                    Quantum resistant cryptography
                  </span>
                  <span className="timeline__item__circle" />
                  <span className="timeline__item__month u-opacity--30">
                    Future development
                  </span>
                </li>
              </ul>
            </section>
          </div>
        </main>
      </section>
    );
  }
}
