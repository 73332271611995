import React from 'react';

interface State {
  tolAddress: string;
  ethAddress: string;
  errorMessage?: string;
}

export class Converter extends React.Component<Record<string, unknown>, State> {
  state: State = {
    tolAddress: '',
    ethAddress: '',
    errorMessage: undefined,
  };

  handleTOLAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    this.setState({ tolAddress: value });
  };

  handleConvert = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { tolAddress } = this.state;

    if (tolAddress.length > 1 && !tolAddress.startsWith('54')) {
      this.setState({
        errorMessage: 'Address should start with 54!',
        ethAddress: '',
      });
      return;
    }

    if (tolAddress.length !== 50) {
      this.setState({
        errorMessage: 'Address length must be 50 hex characters!',
        ethAddress: '',
      });
      return;
    }

    const regex = new RegExp('^.{2}(.{40})');
    const ethAddress = '0x' + regex.exec(tolAddress)![1];

    this.setState({ ethAddress, errorMessage: undefined });
  };

  render() {
    const { tolAddress, ethAddress, errorMessage } = this.state;

    return (
      <section className="converter">
        <p className="u-type--gamma u-spc-bottom--med u-type--center">
          Convert your TOL address to ETH address
        </p>
        <form
          onSubmit={this.handleConvert}
          method="post"
          className="newsletter__form"
          noValidate
        >
          <input
            type="text"
            placeholder="Your TOL address.."
            className="input input--med"
            value={tolAddress}
            name="tolAddress"
            onChange={this.handleTOLAddressChange}
          />
          <button type="submit" className="btn btn--primary btn--med">
            Convert
          </button>
        </form>
        {ethAddress && (
          <p className="u-spc-top--med">
            Your ETH address is: <strong>{ethAddress}</strong>
          </p>
        )}
        {errorMessage && (
          <p className="u-spc-top--med u-color--warning">{errorMessage}</p>
        )}
      </section>
    );
  }
}
