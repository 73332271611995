import { images } from 'main/assets';
import { SEO } from 'main/components';
import ScrollMagic from 'scrollmagic';
import ScrollableAnchor from 'react-scrollable-anchor';
import anime from 'animejs';
import React from 'react';

interface State {
  hoveredTokensaleElementId?: string;
  hoverFundsElementId?: string;
  // currency: string;
}
// require('debug.addIndicators');
export class TokenSale extends React.Component<Record<string, unknown>, State> {
  state = {
    hoveredTokensaleElementId: undefined,
    hoverFundsElementId: undefined,
    //  currency: 'USD'
  };
  // refs for getting the height of an element on resize
  private sectionRef: React.RefObject<HTMLElement>;

  // SEO props
  private seoProps = {
    SEOTitle: 'Tolar - Token sale',
    SEODescription:
      'Tolar is a community governed crypto-currency featuring scalable, fast, secure, and fair transactions.',
    SEOUrl: 'dummyUrl',
  };

  // ScrollMagic scenes
  private sectionScene = new ScrollMagic.Scene();

  // ScrollMagic controller
  private scrollMagicController = new ScrollMagic.Controller({
    globalSceneOptions: { triggerHook: '0' },
  });

  constructor(props: any) {
    super(props);
    this.sectionRef = React.createRef();
  }

  handleSectionResize = () => {
    this.sectionScene.duration(this.sectionRef.current!.clientHeight);
  };

  setScrollMagicScenes = () => {
    this.sectionScene
      .triggerElement('.js-section-price')
      .setClassToggle('.container', 'light')
      .duration(this.sectionRef.current!.clientHeight)
      // .addIndicators()
      .addTo(this.scrollMagicController);
  };

  componentDidMount() {
    this.setScrollMagicScenes();
    window.addEventListener('resize', this.handleSectionResize);

    /* Animate splash header of the section to have a nice fade in */
    const splashAnimation = anime.timeline();

    splashAnimation
      .add({
        targets: '.contentpage__title',
        opacity: [0, 1],
        translateY: [-40, 0],
        duration: 800,
        delay: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__subtitle',
        opacity: [0, 0.6],
        translateY: [40, 0],
        duration: 800,
        easing: 'easeOutQuint',
      })
      .add({
        targets: '.contentpage__header__toc',
        opacity: [0, 1],
        duration: 800,
        easing: 'easeOutQuint',
      });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleSectionResize);
    this.scrollMagicController.destroy(true);
  }

  hoverTokensaleDetails = (event: React.SyntheticEvent<HTMLElement>) => {
    this.setState({
      hoveredTokensaleElementId: event!.currentTarget!.dataset!.id,
    });
  };

  discardTokensaleHover = () => {
    this.setState({
      hoveredTokensaleElementId: undefined,
    });
  };

  hoverFundsDetails = (event: React.SyntheticEvent<HTMLElement>) => {
    this.setState({
      hoverFundsElementId: event!.currentTarget!.dataset!.id,
    });
  };

  discardFundsHover = () => {
    this.setState({
      hoverFundsElementId: undefined,
    });
  };

  render() {
    const { hoveredTokensaleElementId, hoverFundsElementId } = this.state;

    return (
      <section className="contentpage tokensale section--dark">
        <SEO {...this.seoProps} />
        <header className="contentpage__header">
          <div className="wrapper wrapper--medium">
            <div className="contentpage__title">
              <p className="u-type--delta u-type--weight--thin u-type--typography--compressed u-type--upper u-type--spacing--extra-wide u-type--secondary u-opacity--60 u-spc-bottom--sml">
                Tolar
              </p>
              <h1 className="u-type--alpha u-display--b u-type--upper u-type--weight--ultra u-type--negative u-spc-bottom--lrg">
                Token Sale
              </h1>
            </div>
            <p className="contentpage__subtitle u-type--zeta u-type--secondary u-type--typography--wide u-opacity--70">
              We would like to thank all of our investors and the community for
              the amazing support that we have received throughout this journey.
              This is only the beginning.
            </p>
            <ul className="contentpage__header__toc">
              <li>
                <a href="#token-metrics">
                  <span className="contentpage__header__toc__number">01</span>
                  <span className="contentpage__header__toc__title">
                    Token metrics
                  </span>
                </a>
              </li>
              <li>
                <a href="#token-allocation">
                  <span className="contentpage__header__toc__number">02</span>
                  <span className="contentpage__header__toc__title">
                    Token allocation
                  </span>
                </a>
              </li>
              <li>
                <a href="#funds-distribution">
                  <span className="contentpage__header__toc__number">03</span>
                  <span className="contentpage__header__toc__title">
                    Funds distribution
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </header>
        <main>
          <ScrollableAnchor id={'token-metrics'}>
            <section className="tokensale__metrics">
              <div className="wrapper">
                <header className="tokensale__metrics__heading u-type--negative">
                  <div className="u-type--weight--ultra u-type--zeta u-type--complementary">
                    01
                  </div>
                  <h1 className="u-type--beta u-type--upper u-type--weight--thin">
                    <span className="u-type--weight--ultra">Token</span>
                    <br />
                    metrics
                  </h1>
                  <p className="tokensale__metrics__info u-type--micro u-opacity--50">
                    Minimum contribution for private sale was equivalent to 50
                    ETH for individuals and 1000 ETH for private pools. Team and
                    early supporters’ tokens are locked for 24 months after ICO.
                    Private sale bonus tokens are locked for 3 months after ICO.
                  </p>
                </header>
                <div className="tokensale__metrics__content u-type--negative">
                  <p className="u-type--micro u-type--secondary u-type--typography--wide u-opacity--70 u-spc-bottom--xlrg">
                    ICO has ended on September 20, 2018. The total amount raised
                    during the private sale, presale and ICO is 28,807.01 ETH.
                    Tokens were distributed on September 30, 2018.
                  </p>
                  <div className="tokensale__metrics__key">
                    <div className="tokensale__metrics__key__item">
                      <img src={images.iconSaleTolar} alt="Tolar token name" />
                      <h2 className="u-type--epsilon u-type--weight--ultra">
                        Tolar <span className="u-type--eta">(TOL)</span>
                      </h2>
                      <p className="u-type--eta u-type--typography--wide u-type--secondary u-opacity--70">
                        Token name
                      </p>
                    </div>
                    <div className="tokensale__metrics__key__item">
                      <div className="tokensale__metrics__progress">
                        <div className="progress-circle">
                          <div className="left-half-clipper">
                            <div className="first50-bar" />
                            <div className="value-bar" />
                          </div>
                        </div>
                      </div>
                      <h2 className="u-type--epsilon u-type--weight--ultra">
                        35,00 %
                      </h2>
                      <p className="u-type--eta u-type--typography--wide u-type--secondary u-opacity--70">
                        Percentage sold
                      </p>
                    </div>
                    <div className="tokensale__metrics__key__item">
                      <img src={images.iconSaleHardcap} alt="Hard Cap" />
                      <h2 className="u-type--epsilon u-type--weight--ultra">
                        45,000 ETH
                      </h2>
                      <p className="u-type--eta u-type--typography--wide u-type--secondary u-opacity--70">
                        Hard cap
                      </p>
                    </div>
                    <div className="tokensale__metrics__key__item">
                      <img src={images.iconSaleSoftcap} alt="Soft Cap" />
                      <h2 className="u-type--epsilon u-type--weight--ultra">
                        20,000 ETH
                      </h2>
                      <p className="u-type--eta u-type--typography--wide u-type--secondary u-opacity--70">
                        Soft cap
                      </p>
                    </div>
                    <div className="tokensale__metrics__key__item">
                      <img
                        src={images.iconSaleGenerated}
                        alt="Total generated"
                      />
                      <h2 className="u-type--epsilon u-type--weight--ultra">
                        1 billion
                      </h2>
                      <p className="u-type--eta u-type--typography--wide u-type--secondary u-opacity--70">
                        Total Tolars generated
                      </p>
                    </div>
                  </div>
                  <div>
                    <dl className="tokensale__list">
                      <dt className="tokensale__list__label u-type--secondary u-opacity--70">
                        Sold at pre-ICO:
                      </dt>
                      <dd className="tokensale__list__value">5.25%</dd>
                      <dt className="tokensale__list__label u-type--secondary u-opacity--70">
                        Sold at public ICO:
                      </dt>
                      <dd className="tokensale__list__value">8.75%</dd>
                      <dt className="tokensale__list__label u-type--secondary u-opacity--70">
                        Sold at private sale:
                      </dt>
                      <dd className="tokensale__list__value">21,0%</dd>
                      <dt className="tokensale__list__label u-type--secondary u-opacity--70">
                        Private sale bonus:
                      </dt>
                      <dd className="tokensale__list__value">20,0%</dd>
                      <dt className="tokensale__list__label u-type--secondary u-opacity--70">
                        Pre-ICO bonus:
                      </dt>
                      <dd className="tokensale__list__value">10,0%</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </section>
          </ScrollableAnchor>
          <section
            ref={this.sectionRef}
            className="js-section-price tokensale__details section--light"
          >
            <div className="wrapper">
              <div className="tokensale__details__intro">
                <p className="u-type--zeta u-opacity--70">
                  Minimum contribution for private sale is equivalent to 50 ETH
                  for individuals and 1000 ETH for private pools. Team and early
                  supporters’ tokens are locked for 24 months after ICO. Private
                  sale bonus tokens are locked for 3 months after ICO.
                </p>
              </div>

              <ScrollableAnchor id={'token-allocation'}>
                <div className="tokensale__details__allocation">
                  <div className="tokensale__details__allocation__heading">
                    <div className="u-type--weight--ultra u-type--micro u-type--complementary">
                      02
                    </div>
                    <h2 className="u-type--epsilon u-type--upper u-type--weight--thin">
                      <span className="u-type--weight--ultra">Token</span>
                      <br />
                      Allocation
                    </h2>
                  </div>
                  <div className="tokensale__details__allocation__content">
                    <dl className="tokensale__list">
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="1"
                      >
                        Investors:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="1"
                      >
                        35%
                      </dd>
                      <dt className="tokensale__list__label indented">
                        Private sale:
                      </dt>
                      <dd className="tokensale__list__value indented">21%</dd>
                      <dt className="tokensale__list__label indented">
                        Pre-ICO:
                      </dt>
                      <dd className="tokensale__list__value indented">5.25%</dd>
                      <dt className="tokensale__list__label indented">
                        Public ICO:
                      </dt>
                      <dd className="tokensale__list__value indented">8.75%</dd>
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="2"
                      >
                        Founders:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="2"
                      >
                        20%
                      </dd>
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="3"
                      >
                        TOLAR Development Fond:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="3"
                      >
                        32%
                      </dd>
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="4"
                      >
                        Proof of Stake Network Start Nodes:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="4"
                      >
                        8%
                      </dd>
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="5"
                      >
                        Developers:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="5"
                      >
                        2,5%
                      </dd>
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="6"
                      >
                        Advisors:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardTokensaleHover}
                        onMouseOver={this.hoverTokensaleDetails}
                        data-id="6"
                      >
                        2,5%
                      </dd>
                    </dl>
                    <div className="tokensale__graph">
                      <div
                        className={`tokensale__graph__value value-1 ${
                          hoveredTokensaleElementId &&
                          hoveredTokensaleElementId === '1'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '35%' }}
                      />
                      <div
                        className={`tokensale__graph__value value-2 ${
                          hoveredTokensaleElementId &&
                          hoveredTokensaleElementId === '2'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '20%' }}
                      />
                      <div
                        className={`tokensale__graph__value value-3 ${
                          hoveredTokensaleElementId &&
                          hoveredTokensaleElementId === '3'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '32%' }}
                      />
                      <div
                        className={`tokensale__graph__value value-4 ${
                          hoveredTokensaleElementId &&
                          hoveredTokensaleElementId === '4'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '8%' }}
                      />
                      <div
                        className={`tokensale__graph__value value-5 ${
                          hoveredTokensaleElementId &&
                          hoveredTokensaleElementId === '5'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '2.5%' }}
                      />
                      <div
                        className={`tokensale__graph__value value-6 ${
                          hoveredTokensaleElementId &&
                          hoveredTokensaleElementId === '6'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '2.5%' }}
                      />
                    </div>
                  </div>
                </div>
              </ScrollableAnchor>
              <ScrollableAnchor id={'funds-distribution'}>
                <div className="tokensale__details__distribution">
                  <div className="tokensale__details__distribution__heading">
                    <div className="u-type--weight--ultra u-type--micro u-type--complementary">
                      03
                    </div>
                    <h2 className="u-type--epsilon u-type--upper u-type--weight--thin">
                      <span className="u-type--weight--ultra">Funds</span>
                      <br />
                      Distribution
                    </h2>
                  </div>
                  <div className="tokensale__details__distribution__content">
                    <dl className="tokensale__list">
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardFundsHover}
                        onMouseOver={this.hoverFundsDetails}
                        data-id="1"
                      >
                        Carrying out operations:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardFundsHover}
                        onMouseOver={this.hoverFundsDetails}
                        data-id="1"
                      >
                        55%
                      </dd>
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardFundsHover}
                        onMouseOver={this.hoverFundsDetails}
                        data-id="2"
                      >
                        Research &amp; development team:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardFundsHover}
                        onMouseOver={this.hoverFundsDetails}
                        data-id="2"
                      >
                        30%
                      </dd>
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardFundsHover}
                        onMouseOver={this.hoverFundsDetails}
                        data-id="3"
                      >
                        Marketing campaigns:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardFundsHover}
                        onMouseOver={this.hoverFundsDetails}
                        data-id="3"
                      >
                        10%
                      </dd>
                      <dt
                        className="tokensale__list__label"
                        onMouseOut={this.discardFundsHover}
                        onMouseOver={this.hoverFundsDetails}
                        data-id="4"
                      >
                        Conducting legal compliance:
                      </dt>
                      <dd
                        className="tokensale__list__value"
                        onMouseOut={this.discardFundsHover}
                        onMouseOver={this.hoverFundsDetails}
                        data-id="4"
                      >
                        5%
                      </dd>
                    </dl>
                    <div className="tokensale__graph">
                      <div
                        className={`tokensale__graph__value value-1 ${
                          hoverFundsElementId && hoverFundsElementId === '1'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '55%' }}
                      />
                      <div
                        className={`tokensale__graph__value value-2 ${
                          hoverFundsElementId && hoverFundsElementId === '2'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '30%' }}
                      />
                      <div
                        className={`tokensale__graph__value value-3 ${
                          hoverFundsElementId && hoverFundsElementId === '3'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '10%' }}
                      />
                      <div
                        className={`tokensale__graph__value value-4 ${
                          hoverFundsElementId && hoverFundsElementId === '4'
                            ? ' active'
                            : ''
                        }`}
                        style={{ width: '5%' }}
                      />
                    </div>
                  </div>
                </div>
              </ScrollableAnchor>
            </div>
          </section>
        </main>
      </section>
    );
  }
}
